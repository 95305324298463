import { object, string, number, mixed } from 'yup'

export const defaultCrossSectionFormValues = () => {
  return {
    materialType: 'softwoodMaterial',
    steelShape: '',
    shape: {
      height: 10,
      width: 10,
      kind_literal: 'RectangularCS',
    },
    material: {
      kind: 'C24',
      identifier: 'C24',
    },
    usage_class: 'One',
  }
}

// Shape schema based on the type of material
const rectangularCSShapeSchema = object({
  kind_literal: string().oneOf(['RectangularCS']).required(),
  // TODO: it should be strictly larger than 0, but this does not work yet
  width: number().required().min(0, 'Width must be non-negative'),
  height: number().required().min(0, 'Height must be non-negative'),
})

export const steelCSShapeSchema = object({
  kind_literal: string().oneOf(['SteelCS']).required(),
  profile: string().required(),
  height: number().required().positive('Height must be greater than 0'),
  width: number().required().positive('Width must be greater than 0'),
})

const materialWithIdentifierSchema = object({
  kind: string().oneOf(['Glulam', 'Softwood', 'LVL', 'Steel']).required(),
  identifier: string().required(),
})

const crossSectionShapeSchema = mixed().test('shape-validation', 'Invalid shape', function (value) {
  if (!value) return false

  switch (value.kind_literal) {
    case 'RectangularCS':
      return rectangularCSShapeSchema.isValidSync(value)
    case 'SteelCS':
      return steelCSShapeSchema.isValidSync(value)
    default:
      return false
  }
})

export const crossSectionSchema = object({
  material: materialWithIdentifierSchema.required(),
  materialType: string().oneOf(['softwoodMaterial', 'glulamMaterial', 'steelMaterial']).required(),
  shape: crossSectionShapeSchema.required(),
  usage_class: string()
    .oneOf(['One', 'Two', 'Three'])
    .when('materialType', {
      is: (materialType: string) =>
        materialType === 'softwoodMaterial' || materialType === 'glulamMaterial',
      then: schema => schema.required(),
      otherwise: schema => schema.optional(),
    }),
})

export const crossSectionFormSchema = object({
  crossSection: crossSectionSchema.required(),
})
