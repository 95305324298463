import React from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material'
import { TargetElementSupportSettingsPolicy } from '../enums'

const policyLabels = {
  [TargetElementSupportSettingsPolicy.DoNotPreserve]: 'Auflagereinstellungen neu initialisieren',
  [TargetElementSupportSettingsPolicy.PreserveSupportSettings]: 'Auflagereinstellungen beibehalten',
  // [TargetElementSupportGeometryPolicy.PreserveOverhang]: 'Überstand beibehalten',
}

const TargetElementSupportSettingsFormFields = () => {
  const { control } = useFormContext()

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">Einstellungen für darunterliegende Bauteile</FormLabel>
      <Controller
        name="settings.targetElementSupportSettingsPolicy"
        control={control}
        render={({ field }) => (
          <RadioGroup {...field}>
            {Object.entries(policyLabels).map(([policy, label]) => (
              <FormControlLabel key={policy} value={policy} control={<Radio />} label={label} />
            ))}
          </RadioGroup>
        )}
      />
    </FormControl>
  )
}

export { TargetElementSupportSettingsFormFields }
