import React from 'react'
import { useWatch } from 'react-hook-form'
import { useResultsQueryParams } from '@resultsHooks'
import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { CentimeterField, Select, TextField } from '@ui/forms'
import { KC90TextIcon, UtilizationC90TextIcon } from '@ui/icons/misc'
import { useElementLabel } from '@editorHooks'
import { calculateOverhangLintelToRip, renderOverhang, renderUtilization } from './utils'

const CellWithRightBorder = styled(TableCell)(({ theme }) => ({
  borderRightWidth: 2,
  borderRightColor: theme.palette.grey[300],
  borderRightStyle: 'solid',
}))

export interface BundleItem {
  check: SteelCompressionCheck | SupportCompressionStructuralCheck
  targetCrossSection: ElementCSAssignment
  supportConfig: SupportGeometry
}

const FormFields = () => {
  const {
    params: { selectedCheckPosition },
    actions: { setSelectedCheckPosition },
  } = useResultsQueryParams()

  const getLabel = useElementLabel()
  const formBundles: BundleItem[] = useWatch({ name: 'formBundles' })

  return (
    <TableContainer component={Paper} sx={{ maxHeight: '55vh', width: '800px' }}>
      <Table stickyHeader size="small" sx={{ '.MuiTableCell-root': { paddingX: 1 } }}>
        <TableHead>
          <TableRow>
            <CellWithRightBorder align="center" colSpan={3}></CellWithRightBorder>
            <CellWithRightBorder align="center" colSpan={3}>
              darunterliegendes Bauteil
            </CellWithRightBorder>
            <TableCell align="center" colSpan={3}>
              Einstellungen Auflagerpressung
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell>
              <UtilizationC90TextIcon />
            </TableCell>
            <CellWithRightBorder>Pos</CellWithRightBorder>
            <TableCell align="center">Bauteil</TableCell>
            <TableCell align="center">Material</TableCell>
            <CellWithRightBorder align="center">
              <Stack direction="column">
                <Typography>Breite</Typography>
                <Typography>Höhe</Typography>
              </Stack>
            </CellWithRightBorder>
            <TableCell align="center">
              <Stack direction="column">
                <Typography>
                  l<sub>A</sub> + ü
                </Typography>
                <Typography>b</Typography>
              </Stack>
            </TableCell>
            <TableCell align="center">Überstand</TableCell>
            <TableCell align="center">
              <KC90TextIcon />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {formBundles.map(({ check, targetCrossSection }, index) => {
            const supportConfig = formBundles?.[index]?.supportConfig
            const ripCS = targetCrossSection?.element_cs
            const overhang = calculateOverhangLintelToRip(supportConfig, ripCS)
            return (
              <TableRow
                key={check.relative_position}
                onClick={() => setSelectedCheckPosition(check.relative_position)}
                selected={selectedCheckPosition === check.relative_position}
              >
                <TableCell>{index + 1}</TableCell>
                <TableCell>{renderUtilization(check.max_utilization)}</TableCell>
                <CellWithRightBorder>{check.relative_position.toFixed(2)}</CellWithRightBorder>
                <TableCell>
                  {targetCrossSection ? getLabel(targetCrossSection.element_guid) : 'n/a'}
                </TableCell>
                <TableCell>
                  {targetCrossSection && (
                    <TextField
                      name={`formBundles[${index}].targetCrossSection.element_cs.material.identifier`}
                      disabled={true}
                      sx={{
                        '& .MuiOutlinedInput-notchedOutline': {
                          padding: '0 4px',
                        },
                        '& input': {
                          padding: '4px 8px',
                        },
                      }}
                    />
                  )}
                </TableCell>
                <CellWithRightBorder>
                  {targetCrossSection && (
                    <Stack direction="column" spacing={1}>
                      <CentimeterField
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: 'orange',
                            },
                          },
                          '& .MuiOutlinedInput-notchedOutline': {
                            padding: '0 4px',
                          },
                          '& input': {
                            padding: '4px 8px',
                          },
                        }}
                        name={`formBundles[${index}].targetCrossSection.element_cs.shape.width`}
                        data-cy={`txt-cs-width-${index}`}
                        disabled={targetCrossSection.element_cs.material.kind === 'Steel'}
                      />
                      <CentimeterField
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            '& .MuiOutlinedInput-notchedOutline': {
                              padding: '0 4px',
                              borderColor: 'blue',
                            },
                            '& input': {
                              padding: '4px 8px',
                            },
                          },
                        }}
                        name={`formBundles[${index}].targetCrossSection.element_cs.shape.height`}
                        data-cy={`txt-cs-height-${index}`}
                        disabled={targetCrossSection.element_cs.material.kind === 'Steel'}
                      />
                    </Stack>
                  )}
                </CellWithRightBorder>
                <TableCell>
                  <Stack direction="column" spacing={1}>
                    <CentimeterField
                      name={`formBundles[${index}].supportConfig.length`}
                      data-cy={`txt-config-length-${index}`}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor: 'orange',
                          },
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                          padding: '0 4px',
                        },
                        '& input': {
                          padding: '4px 8px',
                        },
                      }}
                    />
                    <CentimeterField
                      name={`formBundles[${index}].supportConfig.width`}
                      data-cy={`txt-config-width-${index}`}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& .MuiOutlinedInput-notchedOutline': {
                            padding: '0 4px',
                            borderColor: 'blue',
                          },
                          '& input': {
                            padding: '4px 8px',
                          },
                        },
                      }}
                    />
                  </Stack>
                </TableCell>
                <TableCell align="center">
                  <Stack direction="column" spacing={1}>
                    <Typography>{renderOverhang(overhang)}</Typography>
                    <Typography>{'...'}</Typography>
                  </Stack>
                </TableCell>
                <TableCell>
                  <Select
                    size="small"
                    name={`formBundles[${index}].supportConfig.k_c_90`}
                    options={[
                      { value: 1.0, label: '1.0' },
                      { value: 1.25, label: '1.25' },
                      { value: 1.5, label: '1.5' },
                      { value: 1.75, label: '1.75' },
                    ]}
                    sx={{
                      '& .MuiSelect-select': {
                        padding: '4px 8px',
                      },
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderWidth: '1px',
                        },
                      },
                    }}
                  />
                </TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default FormFields
