import React, { ReactElement, useMemo } from 'react'
import { isArray, isUndefined, maxBy, minBy, toNumber } from 'lodash-es'
import { Vector3 } from 'three'
import { useTheme } from '@mui/material/styles'
import { toImmutable } from '@modugen/scene/lib/utils'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'
import ShapeMesh from '../ShapeMesh'

interface Props extends IntervalProps {
  interval: StiffeningSegment
  index: number
  wallPoints: ImmutableVector3[]
  pointerPropagation?: boolean
  noPointerInteractions?: boolean
  opacity?: number
}

const thickness = 0.02

const StiffeningMeshElement = ({
  wallPoints,
  interval,
  index,
  pointerPropagation,
  noPointerInteractions,
  opacity,
  onClick = () => null,
  selectedIds = new Set(),
}: Props): ReactElement => {
  const { scenePalette } = useTheme()
  const typedSelectedIds = useMemo(() => {
    return isArray(selectedIds) ? new Set(selectedIds) : selectedIds
  }, [selectedIds])

  const pointsByInterval = useMemo(() => {
    const [initialStart, end] = wallPoints
    const direction = end.sub(initialStart).normalize()
    let height = 0
    if (interval.effective_height === undefined) {
      // Stiffening Proposals don't have a height yet
      const zMax = maxBy(wallPoints, 'z')?.z || 0
      const zMin = minBy(wallPoints, 'z')?.z || 0
      height = zMax - zMin
    } else {
      height = interval.effective_height
    }

    const bottomLeft = new Vector3().addVectors(
      initialStart.v,
      direction.v.multiplyScalar(toNumber(interval.interval.lower)),
    )
    const distance = toNumber(interval.interval.upper) - toNumber(interval.interval.lower)

    const bottomRight = new Vector3().addVectors(bottomLeft, direction.v.multiplyScalar(distance))
    const heightOffset = new Vector3(0, 0, height)
    const topRight = new Vector3().addVectors(bottomRight, heightOffset)
    const topLeft = new Vector3().addVectors(bottomLeft, heightOffset)
    const directedPoints = [bottomLeft, bottomRight, topRight, topLeft]

    const v1 = bottomRight.clone().sub(bottomLeft).normalize()
    const v2 = topLeft.clone().sub(bottomLeft).normalize()
    const extrusionDirection = v1.cross(v2)

    const directedPointsImmutable = directedPoints.map(p => toImmutable(p))

    return {
      points: directedPointsImmutable,
      selectable: interval.selectable === 'Selectable',
      stiffening: interval.stiffening === 'Stiffening',
      localId: interval.localId,
      guid: interval.guid,
      shapeData: {
        element_guid: interval.element_guid,
        domains: [],
        shape: {
          points: directedPointsImmutable.map(point => {
            return point.add(toImmutable(extrusionDirection).multiplyScalar(-thickness / 2))
          }),
          guid: '',
        },
        guid: '',
        thickness,
        openings: [],
        placement: undefined,
        storey: '',
      },
    }
  }, [wallPoints, interval])

  const { shapeData, selectable, stiffening, localId, guid } = pointsByInterval
  const isSelected = typedSelectedIds.has(localId as string) || typedSelectedIds.has(guid as string)

  return (
    <ShapeMesh
      renderOrder={100 + index}
      data={shapeData}
      shapeColor={
        isSelected
          ? scenePalette.selection
          : stiffening
          ? scenePalette.elements3d.stiffening
          : undefined
      }
      applyThickness
      cursor={selectable ? 'pointer' : 'not-allowed'}
      outlines={false}
      pointerPropagation={pointerPropagation}
      noPointerInteractions={noPointerInteractions}
      onClick={onClick}
      userData={{
        localId,
        selectable,
        stiffening,
        guid,
      }}
      meshMaterialProps={{
        // polygonOffset: true,
        // polygonOffsetUnits: -1000 + (index + 1) * 10,
        opacity: isUndefined(opacity) ? (selectable ? 0.4 : 0) : opacity,
        transparent: true,
      }}
    />
  )
}

export default StiffeningMeshElement
