import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { find, reject } from 'lodash-es'
import { closeSnackbar, enqueueSnackbar } from 'notistack'
import { useCameraStore } from '@modugen/scene/lib/controllers/CameraController/cameraStore'
import { useTapelineStore } from '@modugen/scene/lib/controllers/TapelineController/tapelineStore'
import {
  useModelStore,
  useEditElementStore,
  useControlStore,
  useSystemManagerStore,
  roofStoreyKey,
} from '@editorStores'
import { useElementTypes, useTypeInteraction } from '@editorHooks'
import useVisibleModel from 'src/components/pages/Editor/hooks/useVisibleModel'
import { useHotkeys_0to9WithNumpad, useHotkeys_Ctrl_0to9 } from 'src/hooks/useHotkeysShiftX'
import { useFilterState } from '../../hooks/useFilterState'
import useStructuralPlanningQueryParams from '../../hooks/useStructuralPlanningQueryParams'
import {
  VerticalSlabDrawer2D,
  BeamDrawer,
  Beam2D,
  ColumnDrawer,
  Column2D,
  FloorPlan,
  WallDrawer,
  RoofSlab2D,
  VerticalRoofDrawer2D,
  PurlinDrawer,
  VerticalRoofSlab2D,
} from './components'
import FreeformVerticalRoofDrawer from './components/FreeformVerticalRoofDrawer'
import Purlin2D from './components/Purlin2D'
import Rip2D from './components/Rip2D'
import VerticalSlab2D from './components/VerticalSlab2D'
import WallEdit from './components/WallEdit'
import { WallRipDrawer } from './components/WallRipDrawer'
import { useElementsPerStorey } from './hooks'
import usePrimaryWallDirection from './hooks/useDominantWallDirection'

const getRipCrossSectionShape = (rip: Rip, guidToCrossSection: Record<string, CrossSection>) => {
  if (rip.position_guid in guidToCrossSection) {
    return guidToCrossSection[rip.position_guid].shape
  } else {
    return { width: 0.3, height: 0.3, kind_literal: 'RectangularCS' } as RectangularCSShape
  }
}

interface Props {
  selectedElement?: string
  mode?: StructuralPlanningModes
  resetSelectedElement: () => void
}

const FloorplanDrawer = ({ mode, resetSelectedElement }: Props): ReactElement => {
  const {
    params: { storey, drawShapeType },
    modes: {
      isDrawingOpeningsMode,
      isDrawMode,

      isWallRipMode,
    },
    actions: { setStorey, toggleMode },
  } = useStructuralPlanningQueryParams()

  const model = useVisibleModel()
  const wallGuidToDirection = usePrimaryWallDirection(model.walls)
  const setInvisible = useModelStore(state => state.setInvisible)

  const setActiveElement = useEditElementStore(state => state.setActiveElement)
  const activeElement = useEditElementStore(state => state.activeElement)

  const {
    showBeams,
    showVerticalSlabs,
    showColumns,
    showRoofSlabs,
    showPurlins,
    showVerticalRoofSlabs,
    showRips,
    showSlabs,
  } = useFilterState()
  const visibleStoreys = useModelStore(state => state.visibleStoreys)
  const availableStoreys = useModelStore(state => state.availableStoreys)
  const setStoreyVisibility = useModelStore(state => state.setStoreyVisibility)
  const setTypeVisibility = useModelStore(state => state.setTypeVisibility)
  const toggleSingleStoreyVisibility = useModelStore(state => state.toggleSingleStoreyVisibility)

  const isOrthographic = useCameraStore(state => state.isOrthographic)

  const {
    verticalSlabs,
    verticalSlabsInOtherStoreys,
    beams,
    columns,
    beamsInOtherStoreys,
    columnsInOtherStoreys,
    rips,
    ripsInOtherStoreys,
  } = useElementsPerStorey(model, storey as string | undefined, [...visibleStoreys])

  const setShowIndicators = useTapelineStore(state => state.setShowIndicators)
  const setIndicatorType = useTapelineStore(state => state.setIndicatorType)

  const setIsOrthographic = useCameraStore(state => state.setIsOrthographic)

  const setOrthographicDrawMode = useControlStore(state => state.setOrthographicDrawMode)
  const elementCrossSectionAssignment = useSystemManagerStore(
    state => state.elementCrossSectionAssignment,
  )
  const guidToCrossSection = useMemo(() => {
    return elementCrossSectionAssignment.reduce((collector, assignment) => {
      collector[assignment.element_guid] = assignment.element_cs as CrossSection
      return collector
    }, {} as Record<string, CrossSection>)
  }, [elementCrossSectionAssignment])

  const elementTypes = useElementTypes()
  const activeElementType = activeElement ? elementTypes[activeElement] : null

  const [isEditing, setIsEditing] = useState(false)

  const wall = useMemo(() => find(model.walls, ['guid', activeElement]), [model, activeElement])

  // EFFECTS

  useTypeInteraction('none')

  useEffect(() => {
    const availableNonRoofStoreys = new Set(
      reject([...availableStoreys], element => element === roofStoreyKey),
    )
    availableNonRoofStoreys.forEach(element => setStoreyVisibility(element, false))
    storey && setStoreyVisibility(storey, true)
    return () => {
      availableNonRoofStoreys.forEach(availableStorey => setStoreyVisibility(availableStorey, true))
    }
  }, [storey])

  useEffect(() => {
    if (storey) {
      setInvisible(true)
    }

    return () => setInvisible(false)
  }, [storey])

  useEffect(() => {
    if (isOrthographic) {
      setShowIndicators(false)
      setIndicatorType('crosshair')
    }

    return () => {
      setShowIndicators(true)
      setIndicatorType('sphere')
    }
  }, [isOrthographic])

  useEffect(() => {
    const orthoGraphicDrawingMode = !!storey

    setOrthographicDrawMode(orthoGraphicDrawingMode)

    return () => setOrthographicDrawMode(false)
  }, [storey])

  useHotkeys_0to9WithNumpad(
    storeyKey => {
      if (Object.keys(model.storey_boundaries).includes(storeyKey)) {
        toggleSingleStoreyVisibility(storeyKey)
      }
    },
    { enabled: !!storey },
    [model],
  )

  useHotkeys(['r'], () => toggleSingleStoreyVisibility(roofStoreyKey), { enabled: !!storey }, [
    model,
  ])

  useHotkeys_Ctrl_0to9(
    storeyKey => {
      if (Object.keys(model.storey_boundaries).includes(storeyKey)) {
        setStorey(storeyKey)
      }
    },
    { enabled: !!storey },
    [model],
  )

  // hide all non roof storeys when SHIFT+d is pressed
  useHotkeys(
    ['CTRL+r'],
    () => {
      Object.keys(model.storey_boundaries).forEach(storey => setStoreyVisibility(storey, false))
      setStoreyVisibility(roofStoreyKey, true)
    },
    { enabled: !!storey, preventDefault: true },
    [model],
  )

  useHotkeys(
    ['d'],
    () => {
      setTypeVisibility('vertical_slabs' as ElementTypes, !showVerticalSlabs)
      setTypeVisibility('slabs' as ElementTypes, !showSlabs)
    },
    { enabled: !!storey },
    [showVerticalSlabs, showSlabs],
  )

  useEffect(() => {
    if ((mode === 'draw-beams' || mode === 'draw-columns') && storey === roofStoreyKey) {
      enqueueSnackbar('Aktuell ist es nicht möglich Unterzüge oder Stützen im Dach zu zeichen.', {
        variant: 'warning',
        key: 'beam-column-in-roof',
      })
    }
    return () => {
      closeSnackbar('beam-column-in-roof')
    }
  }, [mode, storey])

  const showFloorplan =
    !!storey && ((isDrawingOpeningsMode && !activeElement) || !isDrawingOpeningsMode)

  useEffect(() => {
    setIsOrthographic(showFloorplan || (isDrawingOpeningsMode && !!activeElement))

    return () => setIsOrthographic(false)
  }, [showFloorplan, isDrawingOpeningsMode, activeElement])

  return (
    <>
      <group>
        {showFloorplan && (
          <FloorPlan
            activeStorey={storey}
            visibleStoreys={[...visibleStoreys]}
            wallsSelectable={
              (!isDrawMode || isWallRipMode || mode === 'draw-openings') && !isEditing
            }
            hiddenWalls={
              activeElement &&
              (activeElementType === 'inner_walls' || activeElementType === 'outer_walls')
                ? [activeElement]
                : undefined
            }
            onClickWall={guid => {
              setActiveElement(guid)
              if (mode === 'draw-openings') {
                setStorey(null)
              }
            }}
          />
        )}
        {mode === 'draw-beams' && <BeamDrawer storey={storey as string} enabled={true} />}
        {mode === 'draw-walls' && storey && <WallDrawer activeStorey={storey} />}
        {(activeElementType === 'inner_walls' || activeElementType === 'outer_walls') &&
          mode !== 'wall-rip' &&
          activeElement &&
          wall && (
            <WallEdit
              wallGuid={activeElement}
              onEditStart={() => setIsEditing(true)}
              onEditStop={() => setIsEditing(false)}
              reset={() => setActiveElement(null)}
            />
          )}

        {mode === 'draw-vertical-slabs' && <VerticalSlabDrawer2D storey={storey as string} />}
        {mode === 'draw-columns' && <ColumnDrawer storey={storey as string} />}
        {mode === 'draw-purlins' && <PurlinDrawer />}
        {mode === 'draw-vertical-roofs' && (!drawShapeType || drawShapeType === 'rectangular') && (
          <VerticalRoofDrawer2D storey={storey as string} />
        )}

        {mode === 'draw-vertical-roofs' && drawShapeType === 'freeform' && (
          <FreeformVerticalRoofDrawer resetSelectedElement={resetSelectedElement} />
        )}
        {mode === 'wall-rip' && (
          <WallRipDrawer
            selectedElement={activeElement || undefined}
            setActiveElement={setActiveElement}
            resetMode={toggleMode}
          />
        )}
        {showRoofSlabs && (
          <>
            {model.roof_slabs.map(roofSlab => (
              <RoofSlab2D key={roofSlab.guid} roofSlab={roofSlab} />
            ))}
          </>
        )}
        {/* 2D ELEMENTS positions above the slabs and roof slabs geomeetries */}
        <group position={[0, 0, 0.01]}>
          {showBeams && (
            <>
              {beams.map(beam => (
                <Beam2D
                  key={beam.guid}
                  beam={beam}
                  onClick={
                    !isDrawMode
                      ? guid => {
                          setActiveElement(guid)
                        }
                      : undefined
                  }
                  isActive={activeElement === beam.guid}
                />
              ))}
              {beamsInOtherStoreys.map(beam => (
                <Beam2D key={beam.guid} beam={beam} isSecondary />
              ))}
            </>
          )}
          {showVerticalSlabs && (
            <>
              {verticalSlabs.map(slab => (
                <VerticalSlab2D
                  key={slab.guid}
                  slab={slab}
                  onClick={
                    !isDrawMode
                      ? guid => {
                          setActiveElement(guid)
                        }
                      : undefined
                  }
                  isActive={activeElement === slab.guid}
                />
              ))}
              {verticalSlabsInOtherStoreys.map(slab => (
                <VerticalSlab2D key={slab.guid} slab={slab} />
              ))}
            </>
          )}
          {showPurlins && (
            <>
              {model.purlins.map(purlin => (
                <Purlin2D
                  key={purlin.guid}
                  purlin={purlin}
                  onClick={
                    !isDrawMode
                      ? guid => {
                          setActiveElement(guid)
                        }
                      : undefined
                  }
                  isActive={activeElement === purlin.guid}
                />
              ))}
            </>
          )}
          {showVerticalRoofSlabs && (
            <>
              {model.vertical_roof_slabs.map(roofSlab => (
                <VerticalRoofSlab2D
                  key={roofSlab.guid}
                  roofSlab={roofSlab}
                  onClick={
                    !isDrawMode
                      ? () => {
                          setActiveElement(roofSlab.guid)
                        }
                      : undefined
                  }
                  isActive={activeElement === roofSlab.guid}
                />
              ))}
            </>
          )}
          {/* Render columns slightly higher so that they can be selected ABOVE beams */}
          <group position={[0, 0, 0.01]}>
            {showColumns && (
              <>
                {columns.map(column => (
                  <Column2D
                    key={column.guid}
                    isActive={activeElement === column.guid}
                    column={column}
                    onClick={
                      !isDrawMode
                        ? guid => {
                            setActiveElement(guid)
                          }
                        : undefined
                    }
                  />
                ))}
                {columnsInOtherStoreys.map(column => (
                  <Column2D key={column.guid} column={column} isSecondary />
                ))}
              </>
            )}
            {showRips && (
              <>
                {rips.map(rip => {
                  const csShape = getRipCrossSectionShape(rip, guidToCrossSection)
                  return (
                    <Rip2D
                      key={rip.position_guid}
                      rip={rip}
                      isSecondary
                      width={csShape.width}
                      height={csShape.height}
                      orientation={wallGuidToDirection[rip.wall_guid]}
                    />
                  )
                })}
                {ripsInOtherStoreys.map(rip => {
                  const csShape = getRipCrossSectionShape(rip, guidToCrossSection)
                  return (
                    <Rip2D
                      key={rip.position_guid}
                      rip={rip}
                      isSecondary
                      width={csShape.width}
                      height={csShape.height}
                      orientation={wallGuidToDirection[rip.wall_guid]}
                    />
                  )
                })}
              </>
            )}
          </group>
        </group>
      </group>
    </>
  )
}

export default FloorplanDrawer
export { getSnapLinesFromPolygon } from './utils'
