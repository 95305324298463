import React, { useState } from 'react'
import { MemberChecksTable } from '@resultsComponents'
import { FormControlLabel, Checkbox, Stack } from '@mui/material'
import { InfoTooltip } from '@ui/feedback'
import AccordionWithHiddenExpandedSummary from '@ui/structure/AccordionWithHiddenExpandedSummary'
import { useControlStore } from '@editorStores'

interface Props {
  checksOfPosition: CombinedPositionCheck[]
  settingsOnMember: SettingsOnMember | null
  disableSaving?: boolean
}

const MemberCheckResults = ({
  checksOfPosition,
  settingsOnMember,
  disableSaving = false,
}: Props) => {
  const [displayUnderUtilisedChecks, setDisplayUnderUtilisedChecks] = useState<boolean>(true)
  const isDrawerExpanded = useControlStore(state => state.isDrawerExpanded)

  return (
    <AccordionWithHiddenExpandedSummary
      title="Nachweise"
      defaultExpanded
      data-cy="checks-accordion"
    >
      <Stack sx={{ width: '100%' }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={displayUnderUtilisedChecks}
              onChange={() => setDisplayUnderUtilisedChecks(!displayUnderUtilisedChecks)}
            />
          }
          label={
            isDrawerExpanded ? (
              'Unterausgelastete anzeigen'
            ) : (
              <InfoTooltip text="Unterausgelastete anzeigen" />
            )
          }
        />
        <MemberChecksTable
          positionChecks={checksOfPosition}
          settingsOnMember={settingsOnMember}
          showUnderutilised={displayUnderUtilisedChecks}
          disableSaving={disableSaving}
        />
      </Stack>
    </AccordionWithHiddenExpandedSummary>
  )
}

export default MemberCheckResults
