import React, { useState, ReactNode } from 'react'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  IconButton,
  Stack,
} from '@mui/material'

interface Props {
  title: string
  defaultExpanded?: boolean
  children: ReactNode
  'data-cy'?: string
}

const AccordionWithHiddenExpandedSummary = ({
  title,
  defaultExpanded = false,
  children,
  'data-cy': dataCy,
}: Props) => {
  const [expanded, setExpanded] = useState(defaultExpanded)

  const toggleExpanded = () => setExpanded(!expanded)

  return (
    <Accordion expanded={expanded} onChange={toggleExpanded} data-cy={dataCy}>
      {!expanded && (
        <AccordionSummary
          aria-controls="panel1-content"
          id="panel1-header"
          expandIcon={<AddIcon />}
        >
          <Typography variant="h6" align="center" sx={{ width: '100%' }}>
            {title}
          </Typography>
        </AccordionSummary>
      )}
      <AccordionDetails>
        <Stack direction="row" alignItems="flex-start" justifyContent="space-between">
          {children}
          {expanded && (
            <IconButton onClick={toggleExpanded}>
              <RemoveIcon />
            </IconButton>
          )}
        </Stack>
      </AccordionDetails>
    </Accordion>
  )
}

export default AccordionWithHiddenExpandedSummary
