import React, { useMemo } from 'react'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import { mapValueKey } from '@editorUtils'
import { AxiosError } from 'axios'
import { closeSnackbar, enqueueSnackbar } from 'notistack'
import { Stack } from '@mui/material'
import { Errors, Form } from '@ui/forms'
import SubmitButton from '@ui/forms/SubmitButton'
import { Box } from '@ui/structure'
import { useResultsStore, useSystemManagerStore } from '@editorStores'
import {
  getChecksForCrossSections,
  getElementCrossSectionAssignment,
  getMemberCheckSettings,
  postStartCalcMemberChecks,
} from '@queries'
import { saveSingleMemberCheckSettings, updateElementCrossSectionAssignment } from '@mutations'
import { FormFields as SingleElementCSFormFields } from 'src/components/pages/Editor/components/SingleElementCSForm/components/FormFields'
import { getDefaultValues as getDefaultValuesSingleCSForm } from 'src/components/pages/Editor/components/SingleElementCSForm/components/SingleCSForm/schema'
import { buildErrorMessage } from 'src/constants'
import queryClient from 'src/state/client'
import { getSupportsOfElement } from '../../utils'
import LintelCompressionSettingsFormFields, {
  BundleItem,
} from '../LintelCompressionSettingsForm/formFields'
import { getDefaultFormValues as getDefaultFormValuesCompressionSettingsForm } from '../LintelCompressionSettingsForm/utils'
import { TargetElementSupportSettingsFormFields } from './components/TargetElementSupportSettingsFormFields'
import { UtilizationPreview } from './components/UtilizationPreview'
import { TargetElementSupportSettingsPolicy } from './enums'
import { lintelCSAndAPFormSchema } from './schema'

interface FormData {
  crossSection: CrossSection
  formBundles: BundleItem[]
  supportCompressionChecks: SupportCompressionStructuralCheck[] | SteelCompressionCheck[]
  checkSettings: TimberCheckSettings | TimberSlabCheckSettings
  settings: {
    targetElementSupportSettingsPolicy: TargetElementSupportSettingsPolicy
  }
}

interface MutationData {
  crossSection: ElementCSAssignment
  settingsOnMember: SettingsOnMember
  formBundles: BundleItem[]
  settings: {
    targetElementSupportSettingsPolicy: TargetElementSupportSettingsPolicy
  }
}

interface Props {
  elementGuid: string
  checksOnLintel: StandardPositionCheck[]
  checkSettings: TimberCheckSettings | TimberSlabCheckSettings
  isLoading: boolean
  preSubmit?: () => void
  postSubmit?: () => void
}

const LintelCSAndAPForm = ({
  elementGuid,
  checksOnLintel,
  checkSettings,
  isLoading,
  preSubmit,
  postSubmit,
}: Props) => {
  const { projectId } = useParams()
  const verticalTransmissionGraph = useResultsStore(state => state.verticalTransmissionGraph)
  const setSingleMemberCheckSetting = useResultsStore(state => state.setSingleMemberCheckSetting)
  const memberCheckSettings = useResultsStore(state => state.memberCheckSettings)
  const elementCrossSectionAssignment = useSystemManagerStore(
    state => state.elementCrossSectionAssignment,
  )
  const setSingleCrossSection = useSystemManagerStore(state => state.setSingleCrossSection)

  const elementGuidToCrossSection = useMemo(
    () => mapValueKey(elementCrossSectionAssignment, 'element_guid'),
    [elementCrossSectionAssignment],
  )
  const crossSection = elementGuidToCrossSection[elementGuid].element_cs

  const elementsSupportingSelected = verticalTransmissionGraph
    ? getSupportsOfElement(elementGuid, verticalTransmissionGraph)
    : []
  const supportCompressionChecks = useMemo(
    () =>
      (checksOnLintel.filter(
        check =>
          check.check_type === 'SupportCompression' ||
          check.check_type === 'SteelSupportCompression',
      ) as SupportCompressionStructuralCheck[] | SteelCompressionCheck[]) || [],
    [checksOnLintel],
  )

  const defaultValues = {
    ...getDefaultFormValuesCompressionSettingsForm(
      elementGuidToCrossSection,
      checkSettings,
      supportCompressionChecks,
      elementsSupportingSelected,
    ),
    ...getDefaultValuesSingleCSForm(crossSection),
    settings: {
      targetElementSupportSettingsPolicy:
        TargetElementSupportSettingsPolicy.PreserveSupportSettings,
    },
  }
  const formBundlesToUpdatedCheckSettings = (formBundles: BundleItem[]) => {
    // We save everything each time, only because it is simpler that way
    const updatedConfigs = checkSettings.support_configs.map(config => {
      const bundle = formBundles.find(
        bundle => bundle.supportConfig.relative_position === config.relative_position,
      )
      return bundle ? bundle.supportConfig : config
    })
    return {
      ...checkSettings,
      support_configs: updatedConfigs,
    }
  }

  const { mutateAsync: onSubmit, isLoading: isLoadingInternal } = useMutation(
    (data: MutationData) => {
      // Execute promises sequentially to avoid race condition. This is very important!
      const updateCrossSections = async () => {
        for (const bundle of data.formBundles) {
          await updateElementCrossSectionAssignment.request(
            projectId as string,
            bundle.targetCrossSection,
          )
        }
        await updateElementCrossSectionAssignment.request(projectId as string, data.crossSection)
      }
      return updateCrossSections().then(async () => {
        if (
          data.settings.targetElementSupportSettingsPolicy ===
          TargetElementSupportSettingsPolicy.DoNotPreserve
        ) {
          await saveSingleMemberCheckSettings.request(projectId as string, data.settingsOnMember)
        } else if (
          data.settings.targetElementSupportSettingsPolicy ===
          TargetElementSupportSettingsPolicy.PreserveSupportSettings
        ) {
          // First save settings for the main member
          await saveSingleMemberCheckSettings.request(projectId as string, data.settingsOnMember)

          // Then sequentially save settings for each bundle
          for (const bundle of data.formBundles) {
            const targetCheckSetting = memberCheckSettings.find(
              settings => settings.element_guid === bundle.targetCrossSection.element_guid,
            )
            if (targetCheckSetting) {
              await saveSingleMemberCheckSettings.request(projectId as string, targetCheckSetting)
            }
          }
        }
      })
    },
    {
      onMutate: async (data: MutationData) => {
        // optimistic update
        setSingleCrossSection(data.crossSection.element_guid, data.crossSection.element_cs)
        setSingleMemberCheckSetting(data.settingsOnMember)
        data.formBundles.forEach(bundle => {
          const updatedCrossSection = bundle.targetCrossSection
          if (updatedCrossSection)
            setSingleCrossSection(updatedCrossSection.element_guid, updatedCrossSection.element_cs)
        })
        // TODO: add an option to preserve or update the check settings of the adjusted target elements
        // TODO: I think technically, we would need to invalidate the checks because
        // due to the cross section update, the support geometry of the target elements chaanges
        // which technically causes the support geometry of those elements to change
        // which then cause the checks to be different
      },
      onSuccess: () => {
        enqueueSnackbar('Querschnitt und Auflagereinstellungen erfolgreich gespeichert', {
          variant: 'success',
          preventDuplicate: true,
        })
        // member check settings might have been updated which requires a reload of the member check settings
        queryClient.invalidateQueries(getElementCrossSectionAssignment.getKey(projectId))
        queryClient.invalidateQueries(getMemberCheckSettings.getKey(projectId))
        // note: it might be better to directly rerun the check calculation so that the state remains consistent
        const infoMessage = '[Backend] Aktualisiere MemberCheckDependency ...'
        enqueueSnackbar(infoMessage, { variant: 'info', key: infoMessage, preventDuplicate: true })
        postStartCalcMemberChecks.request(projectId as string).then(() => {
          closeSnackbar(infoMessage)
          const successMessage = '[Backend] MemberCheckDependency aktualisiert.'
          enqueueSnackbar(successMessage, {
            variant: 'success',
            key: successMessage,
            preventDuplicate: true,
          })
        })
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(
          buildErrorMessage(error, 'Fehler beim Speichern der Querschnitts-Zuweisung'),
          { variant: 'error' },
        )
        queryClient.invalidateQueries(getElementCrossSectionAssignment.getKey(projectId))
        queryClient.invalidateQueries(getChecksForCrossSections.getKey(projectId as string))
      },
    },
  )

  const handleSubmit = (data: FormData) => {
    preSubmit?.()

    // then save the updated member check settings
    const formBundles = data.formBundles
    onSubmit({
      crossSection: {
        element_guid: elementGuid,
        element_cs: data.crossSection,
      },
      settingsOnMember: formBundlesToUpdatedCheckSettings(formBundles),
      formBundles: formBundles,
      settings: data.settings,
    })

    postSubmit?.()
  }

  return (
    <Form
      onSubmit={handleSubmit}
      defaultValues={defaultValues}
      validationSchema={lintelCSAndAPFormSchema}
      key={elementGuid}
      data-cy={`frm-lintel-cs-and-ap`}
    >
      <Stack direction="column" spacing={2}>
        <Stack direction="row" spacing={2} justifyContent="space-between">
          <UtilizationPreview elementGuid={elementGuid} />
          <Box width="100%" p={1} border={1} borderColor="grey.200" borderRadius={1}>
            <SingleElementCSFormFields elementType="lintels" isDisabled={false} />
          </Box>
        </Stack>
        <LintelCompressionSettingsFormFields />
        <TargetElementSupportSettingsFormFields />
        <Errors />
        <SubmitButton
          loading={isLoading || isLoadingInternal}
          fullWidth={false}
          data-cy={`btn-lintel-cs-and-ap-submit`}
        />
      </Stack>
    </Form>
  )
}

export default LintelCSAndAPForm
