import React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { filter, find, maxBy, reject } from 'lodash-es'
import {
  TableCell,
  Paper,
  TableContainer,
  TableRow,
  Table,
  TableHead,
  TableBody,
  CircularProgress,
} from '@mui/material'
import { UtilizationC90TextIcon, UtilizationTextIcon } from '@ui/icons/misc'
import { useResultsStore } from '@editorStores'
import { getCalcChecksForElement } from '@queries'
import { BundleItem } from '../../LintelCompressionSettingsForm/formFields'
import { renderUtilization } from '../../LintelCompressionSettingsForm/utils'

const formBundlesToUpdatedCheckSettings = (
  originalCheckSettings: SettingsOnMember,
  formBundles: BundleItem[],
) => {
  // We save everything each time, only because it is simpler that way
  if (
    originalCheckSettings.setting_type === 'steel-column' ||
    originalCheckSettings.setting_type === 'other'
  ) {
    return originalCheckSettings
  }
  const updatedSupportConfigs = originalCheckSettings.support_configs.map(config => {
    const bundle = formBundles.find(
      bundle => bundle.supportConfig.relative_position === config.relative_position,
    )
    return bundle ? bundle.supportConfig : config
  })
  return {
    ...originalCheckSettings,
    support_configs: updatedSupportConfigs,
  }
}

const UtilizationPreview = ({ elementGuid }: { elementGuid: string }) => {
  const { projectId } = useParams()

  const memberCheckSettings = useResultsStore(state => state.memberCheckSettings)

  const { setValue } = useFormContext()

  const formBundles: BundleItem[] = useWatch({ name: 'formBundles' })
  const crossSection: CrossSection = useWatch({ name: 'crossSection' })
  const originalSettingsOnElement = memberCheckSettings.find(
    settings => settings.element_guid === elementGuid,
  )
  const updatedSettingsOnElement = formBundlesToUpdatedCheckSettings(
    originalSettingsOnElement as SettingsOnMember,
    formBundles,
  )

  const queryParams = {
    elementGuid: elementGuid,
    crossSection: crossSection,
    updatedSettingsOnElement,
  }
  const { data: computedChecksData, isLoading: isComputingChecks } = useQuery(
    ['calc-element-checks', queryParams],
    () =>
      getCalcChecksForElement.request(
        projectId as string,
        queryParams.elementGuid,
        queryParams.updatedSettingsOnElement,
        queryParams.crossSection,
      ),
    {
      onSuccess: (data: CrossSectionSuggestion) => {
        const elementPositionChecks = data.checks
        const elementChecksOnlySupportCompression = filter(elementPositionChecks, check =>
          ['SupportCompression', 'SteelSupportCompression'].includes(check.check_type),
        ) as SupportCompressionStructuralCheck[] | SteelCompressionCheck[]
        formBundles.forEach((bundle, idx) => {
          const correspondingCheck = find(elementChecksOnlySupportCompression, {
            relative_position: bundle.check.relative_position,
          })
          if (correspondingCheck) {
            setValue(`formBundles.${idx}.check`, correspondingCheck)
          }
        })
      },
    },
  )
  const elementPositionChecks = computedChecksData && computedChecksData.checks
  const elementChecksWithoutSupportCompression = reject(elementPositionChecks, check =>
    ['SupportCompression', 'SteelSupportCompression'].includes(check.check_type),
  )

  const elementChecksOnlySupportCompression = filter(elementPositionChecks, check =>
    ['SupportCompression', 'SteelSupportCompression'].includes(check.check_type),
  )
  const maxUtilOnlySupportCompression = maxBy(
    elementChecksOnlySupportCompression,
    check => check.max_utilization,
  )?.max_utilization
  const maxUtilWithoutSupportCompression = maxBy(
    elementChecksWithoutSupportCompression,
    check => check.max_utilization,
  )?.max_utilization

  return (
    <TableContainer component={Paper} sx={{ maxWidth: '100px' }}>
      <Table stickyHeader size="small" sx={{ '.MuiTableCell-root': { paddingX: 1 } }}>
        <TableHead>
          <TableRow>
            <TableCell align="center">
              <UtilizationTextIcon />
            </TableCell>
            <TableCell align="center">
              <UtilizationC90TextIcon />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align="center">
              {isComputingChecks ? (
                <CircularProgress size={16} />
              ) : (
                renderUtilization(maxUtilWithoutSupportCompression)
              )}
            </TableCell>
            <TableCell align="center">
              {isComputingChecks ? (
                <CircularProgress size={16} />
              ) : (
                renderUtilization(maxUtilOnlySupportCompression)
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export { UtilizationPreview }
