export const beamThickness = 0.2

export const typeToPolygonOffsetFactor: Record<ElementTypes, number> = {
  outer_walls: 0,
  inner_walls: 0,
  slabs: 0,
  roof_slabs: 0,

  beams: 0.2,
  columns: 0.2,
  vertical_roof_slabs: -1,
  vertical_slabs: -1,
  purlins: 0.2,
  rips: 0,
  lintels: 0,

  slab_beams: 0.2,
  roof_slab_beams: 0.2,
  standard_rips: 0,
  foundation: 0,
}
