import { getRequest } from '../utils'

export const getSnowLoads: RequestTypeVariable = {
  getKey: (projectId: string) => ['getSnowLoads', projectId],
  request: async (id): Promise<SnowLoadConfiguration[]> => {
    const { data } = await getRequest<SnowLoadConfiguration[]>({
      url: `/projects/${id}/snow-load/load-assignment`,
    })

    return data as SnowLoadConfiguration[]
  },
}

export const getLiveLoads: RequestTypeVariable = {
  getKey: (projectId: string) => ['getLiveLoads', projectId],
  request: async (id): Promise<LiveLoadConfiguration[]> => {
    const { data } = await getRequest<LiveLoadConfiguration[]>({
      url: `/projects/${id}/live-load/load-assignment`,
    })

    return data as LiveLoadConfiguration[]
  },
}

export const getSeismicSettings: RequestTypeVariable = {
  getKey: (projectId: string) => ['getSeismicSettings', projectId],
  request: async (id): Promise<SeismicSettings> => {
    const { data } = await getRequest<SeismicSettings>({
      url: `/projects/${id}/seismic-settings`,
    })

    return data as SeismicSettings
  },
}

export const getSimplifiedSeismicModelConfig: RequestTypeVariable = {
  getKey: (projectId: string) => ['getSimplifiedSeismicModelConfig', projectId],
  request: async (id): Promise<SimplifiedSeismicModelConfig> => {
    const { data } = await getRequest<SimplifiedSeismicModelConfig>({
      url: `/projects/${id}/simplified-seismic-config`,
    })
    return data as SimplifiedSeismicModelConfig
  },
}

export const getSimplifiedSeismicModelInputs: RequestTypeVariable = {
  getKey: (projectId: string) => ['getSimplifiedSeismicModelInputs', projectId],
  request: async (id): Promise<SimplifiedSeismicModelInputs> => {
    const { data } = await getRequest<SimplifiedSeismicModelInputs>({
      url: `/projects/${id}/estimated-model-inputs`,
    })
    return data as SimplifiedSeismicModelInputs
  },
}

export const getSimplifiedSeismicResults: RequestTypeVariable = {
  getKey: (projectId: string) => ['getSimplifiedSeismicResults', projectId],
  request: async (id): Promise<SimplifiedSeismicResults> => {
    const { data } = await getRequest<SimplifiedSeismicResults>({
      url: `/projects/${id}/simplified-seismic-results`,
    })
    return data as SimplifiedSeismicResults
  },
}

export const getGlobalFireSettings: RequestTypeVariable = {
  getKey: (projectId: string) => ['getGlobalFireSettings', projectId],
  request: async (id): Promise<GlobalFireSettings> => {
    const { data } = await getRequest<GlobalFireSettings>({
      url: `/projects/${id}/global-fire-settings`,
    })

    return data as GlobalFireSettings
  },
}
