import React from 'react'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import { AxiosError } from 'axios'
import { closeSnackbar, enqueueSnackbar } from 'notistack'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import { LoadingButton } from '@mui/lab'
import { useElementType } from '@editorHooks'
import {
  getChecksForCrossSections,
  getElementCrossSectionAssignment,
  getMemberCheckSettings,
  postStartCalcMemberChecks,
  postStartCSOptimization,
} from '@queries'
import { buildErrorMessage } from 'src/constants'
import queryClient from 'src/state/client'

interface MutationData {
  elementGuid: string
}

interface Props {
  elementGuid: string
}

export const CSOptimizationButton = ({ elementGuid }: Props) => {
  const { projectId } = useParams()
  const elementType = useElementType(elementGuid)

  // mutations

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { mutateAsync: onStartCSOptimization, isLoading } = useMutation(
    (data: MutationData) => postStartCSOptimization.request(projectId as string, data.elementGuid),
    {
      onSuccess: () => {
        enqueueSnackbar('Querschnitt und Auflagereinstellungen erfolgreich gespeichert', {
          variant: 'success',
          preventDuplicate: true,
        })
        // member check settings might have been updated which requires a reload of the member check settings
        queryClient.invalidateQueries(getElementCrossSectionAssignment.getKey(projectId))
        queryClient.invalidateQueries(getMemberCheckSettings.getKey(projectId))
        // note: it might be better to directly rerun the check calculation so that the state remains consistent
        const infoMessage = '[Backend] Aktualisiere MemberCheckDependency ...'
        enqueueSnackbar(infoMessage, { variant: 'info', key: infoMessage, preventDuplicate: true })
        postStartCalcMemberChecks.request(projectId as string).then(() => {
          closeSnackbar(infoMessage)
          const successMessage = '[Backend] MemberCheckDependency aktualisiert.'
          enqueueSnackbar(successMessage, {
            variant: 'success',
            key: successMessage,
            preventDuplicate: true,
          })
        })
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(
          buildErrorMessage(error, 'Fehler beim Speichern der Querschnitts-Zuweisung'),
          { variant: 'error' },
        )
        queryClient.invalidateQueries(getElementCrossSectionAssignment.getKey(projectId))
        queryClient.invalidateQueries(getChecksForCrossSections.getKey(projectId as string))
      },
    },
  )

  // derived variables

  const isDisabled = elementType !== ('lintels' as ElementTypes)

  return (
    <LoadingButton
      disabled={isDisabled}
      loading={isLoading}
      sx={{ minWidth: 0, p: 1 }}
      onClick={() => onStartCSOptimization({ elementGuid })}
    >
      <AutoAwesomeIcon fontSize="inherit" />
    </LoadingButton>
  )
}
