import React, { ReactElement } from 'react'
import { useFormContext } from 'react-hook-form'
import { Divider, Grid, Stack, Typography } from '@mui/material'
import { Checkbox, Label, Select } from '@ui/forms'
import { Box } from '@ui/structure'

const FormFields = (): ReactElement => {
  const { watch, setValue } = useFormContext()

  const isHotDimensioningEnabled = watch('hot_dimensioning_settings.make_hot_dimensioning_checks')

  return (
    <Stack direction="column" spacing={2}>
      <Box>
        <Typography variant="h6">Heißbemessung sichtbarer Bauteile</Typography>

        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Label>Heißbemessung durchführen</Label>
          </Grid>
          <Grid item xs={4}>
            <Checkbox
              name="hot_dimensioning_settings.make_hot_dimensioning_checks"
              onChange={e => {
                if (!e) {
                  setValue('hot_dimensioning_settings.fire_duration', null)
                }
              }}
            />
          </Grid>
        </Grid>

        {isHotDimensioningEnabled && (
          <>
            <Grid item xs={12}>
              <Select
                label="Feuerwiderstandsklasse"
                name="hot_dimensioning_settings.fire_duration"
                options={[
                  { value: 0, label: 'F0' },
                  { value: 1800, label: 'F30' },
                  { value: 3600, label: 'F60' },
                  { value: 5400, label: 'F90' },
                  { value: 7200, label: 'F120' },
                ]}
              />
            </Grid>
          </>
        )}
      </Box>
      <Divider />
      <Box>
        <Typography variant="h6">Heißbemessung von Standardwandrippen</Typography>

        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Label>Heißbemessung durchführen</Label>
          </Grid>
          <Grid item xs={4}>
            <Checkbox name="fire_protection_settings" />
          </Grid>
        </Grid>
      </Box>
    </Stack>
  )
}

export default FormFields
