import React from 'react'
import { Typography } from '@mui/material'
import { convertUnit } from 'src/utils/units'
import { TargetGuidAndRelativePosition } from '../../utils'

export const renderAsCM = (value: number, numeralFormatter = '0.[0]') => {
  const internalValue = 'm'
  const displayUnit = 'cm'
  const convertedValueAsString = convertUnit(value, numeralFormatter, internalValue, displayUnit)
  const renderValue = `${convertedValueAsString} ${displayUnit}`
  return renderValue
}

export const calculateOverhangLintelToRip = (
  supportConfig: SupportGeometry | undefined,
  ripCS: CrossSection | undefined,
): number | undefined => {
  const lSupportConfig = supportConfig?.length
  if (ripCS?.material.kind === 'Steel') {
    // Breite parallel zum Flansch im Fall der Sturz - Rippe konstellation
    const bRip = ripCS?.shape?.width
    return lSupportConfig && bRip ? lSupportConfig - bRip : undefined
  } else {
    // its a wood cross section
    const bRip = ripCS?.shape?.width
    return lSupportConfig && bRip ? lSupportConfig - bRip : undefined
  }
}

export const renderOverhang = (value: number | undefined) => {
  if (value === undefined) return 'n/a'
  const overhangCM = renderAsCM(value, '0')
  return overhangCM
}

export const renderUtilization = (utilization: number | undefined) => {
  if (!utilization) return 'n. a.'
  const roundedValue = utilization.toFixed(2)
  const isOverUtilized = utilization > 1

  return (
    <Typography
      sx={{
        color: isOverUtilized ? 'red' : 'inherit',
        fontWeight: isOverUtilized ? 'bold' : 'inherit',
      }}
    >
      {roundedValue}
    </Typography>
  )
}

export const getDefaultFormValues = (
  elementGuidToCrossSection: Record<string, ElementCSAssignment>,
  checkSettings: TimberCheckSettings | TimberSlabCheckSettings,
  supportCompressionChecks: SupportCompressionStructuralCheck[] | SteelCompressionCheck[],
  elementsSupportingSelected: TargetGuidAndRelativePosition[],
) => {
  const formBundles = supportCompressionChecks.map(check => {
    const supportConfig = checkSettings.support_configs.find(
      config => Number(config.relative_position) === check.relative_position,
    )

    const targetGuid = elementsSupportingSelected.find(
      ({ relativePosition }) => Number(relativePosition) === check.relative_position,
    )?.guid

    const targetCrossSection = targetGuid ? elementGuidToCrossSection[targetGuid] : null

    return {
      check,
      supportConfig,
      targetCrossSection,
    }
  })

  return { formBundles }
}
