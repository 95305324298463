import React, { ReactElement, useMemo, useRef } from 'react'
import { useTapelineSnapTargets } from '@scene'
import { filter, find, isUndefined, last } from 'lodash-es'
import { useSnackbar } from 'notistack'
import { Line3 } from 'three'
import { v4 as uuid } from 'uuid'
import { useTheme } from '@mui/material'
import {
  DrawController,
  InteractiveLine,
  isPointOnLineSegment,
  useTapelineStore,
} from '@modugen/scene/lib'
import { DrawControllerRef } from '@modugen/scene/lib/controllers/DrawController'
import useTapelineCentersSnapTargets from '@modugen/scene/lib/hooks/useTapelineCentersSnapTargets'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'
import { useControlStore, useModelStore } from '@editorStores'
import { useRipSnapTargets } from '../../hooks'
import useModelSnapTargets from '../../hooks/useModelSnapTargets'

interface Props {
  selectedElement?: string
  setActiveElement: (guid: string | null) => void
  resetMode: () => void
}

export const WallRipDrawer = ({
  selectedElement,
  setActiveElement,
}: Props): ReactElement | null => {
  const { enqueueSnackbar } = useSnackbar()
  const { scenePalette } = useTheme()

  const drawControllerRef = useRef<DrawControllerRef>(null)

  const addRip = useModelStore(state => state.addRip)
  const removeRip = useModelStore(state => state.removeRip)
  const rips = useModelStore(state => state.model.rips)

  const isTapelineActive = useTapelineStore(state => state.isActive)

  const snapToCornersAndEdges = useControlStore(state => state.snapToCornersAndEdges)
  const snapToAngles = useControlStore(state => state.snapToAngles)
  const snapOrthogonal = useControlStore(state => state.snapOrthogonal)

  const modelSnapTargets = useModelSnapTargets({ xyOnly: true })
  const tapelineSnapTargets = useTapelineSnapTargets()
  const tapelineCenterTargets = useTapelineCentersSnapTargets()
  const ripSnapTargets = useRipSnapTargets()

  const model = useModelStore(state => state.model)
  const wall = useMemo(() => find(model.walls, { guid: selectedElement }), [model, selectedElement])

  const wallZ = wall?.shape.points[0].z

  if (!wall || !selectedElement || isUndefined(wallZ)) return null

  return (
    <>
      <InteractiveLine
        key={selectedElement}
        mainLineProps={{ name: selectedElement }}
        line={[wall.shape.points[0], wall.shape.points[1]]}
        showHandles={false}
        nonSelectable
        clickDisabled
        cursor="auto"
      />

      <DrawController
        ref={drawControllerRef}
        enabled={!isTapelineActive}
        snapToCornersAndEdges={snapToCornersAndEdges}
        snapToAngles={snapToAngles}
        orthoSnap={snapOrthogonal}
        enableIndicator
        color={scenePalette.elements3d.beams as string}
        additionalSnapTargets={[
          ...ripSnapTargets,
          ...modelSnapTargets,
          ...tapelineSnapTargets,
          ...tapelineCenterTargets,
        ]}
        snapAngle={90}
        drawingAxis={{
          origin: wall.shape.points[0],
          direction: wall.shape.points[0].directionTo(wall.shape.points[1]),
        }}
        xyOnly
        indicatorType="crosshair"
        onDrawEnd={state => {
          const drawPoint = state.drawPoint as ImmutableVector3

          const start = new ImmutableVector3(drawPoint.x, drawPoint.y, wallZ)
          const end = new ImmutableVector3(drawPoint.x, drawPoint.y, wallZ + 3)

          if (
            !isPointOnLineSegment(
              new Line3(wall.shape.points[0].v, wall.shape.points[1].v),
              drawPoint.v,
              0.00001,
            )
          ) {
            enqueueSnackbar('Rippen können nur als Teil einer Wand gezeichnet werden', {
              variant: 'warning',
            })
            return
          }

          const xDirection = wall.shape.points[0].directionTo(
            last(wall.shape.points) as ImmutableVector3,
            true,
          )
          const yDirection = wall.shape.points[0].directionTo(wall.shape.points[1], true)
          const zDirection = xDirection.cross(yDirection).normalize()

          const rip: Rip = {
            wall_guid: selectedElement,
            position_guid: uuid(),
            domain_guid: uuid(), // Generate something here, but ignore
            start: start,
            end: end,
            coordinate_system: {
              origin: start,
              x_direction: xDirection,
              y_direction: yDirection,
              z_direction: zDirection,
              TOLERANCE: 0,
            },
            is_local: true,
          }

          const localRips = filter(rips, { is_local: true })
          localRips.forEach(rip => {
            removeRip(rip.position_guid)
          })

          addRip(rip)
          setActiveElement(rip.position_guid)
        }}
      />
    </>
  )
}
