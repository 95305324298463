import React, { ReactElement, useRef, useState } from 'react'
import { getLineCoordinateSystem } from '@editorUtils'
import { useTapelineSnapTargets } from '@scene'
import { Object3D } from 'three'
import { v4 as uuid } from 'uuid'
import { useTheme } from '@mui/material'
import {
  DraggableLineRef,
  DraggableLine,
  LineHandles,
} from '@modugen/scene/lib/components/Lines/DraggableLine'
import {
  DrawController,
  DrawControllerRef,
  TransientDrawState,
} from '@modugen/scene/lib/controllers/DrawController'
import { getOrientedWorldNormalsForIntersection } from '@modugen/scene/lib/controllers/DrawController/utils'
import { useTapelineStore } from '@modugen/scene/lib/controllers/TapelineController/tapelineStore'
import useTapelineCentersSnapTargets from '@modugen/scene/lib/hooks/useTapelineCentersSnapTargets'
import { toImmutable } from '@modugen/scene/lib/utils'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'
import { useEditElementStore, useControlStore, useModelStore } from '@editorStores'
import { useStructuralPlanningDrawerEsc } from '@structuralPlanningHooks'
import {
  SnapTargetElementType,
  useDefault2DSnapConfigForElementType,
} from 'src/components/pages/Editor/hooks/useDefaultSnapConfigForElementType'
import { useModelSnapTargets, useSlabElevation } from '../../hooks'
import { BeamElements } from '../Beam2D'

interface TransientWallState {
  isCreating?: boolean
  isFirstMove?: boolean
  isUpdating?: boolean
  lineRef?: React.RefObject<DraggableLineRef>
  drawStartSnapGuid?: string
}

interface Props {
  storey: string

  enabled: boolean
}

const minBeamLength = 0.1

const BeamDrawer2D = ({ storey, enabled }: Props): ReactElement => {
  const { scenePalette } = useTheme()
  const drawControllerRef = useRef<DrawControllerRef>(null)
  const lineRef = useRef<DraggableLineRef>(null)
  const transientWallState = useRef<TransientWallState>({})
  const [enableIndicator, setEnableIndicator] = useState(true)
  const [showTempLine, setShowTempLine] = useState(false)
  const slabElevation = useSlabElevation(storey)
  const isTapelineActive = useTapelineStore(state => state.isActive)

  const snapOrthogonal = useControlStore(state => state.snapOrthogonal)
  const snapToCornersAndEdges = useControlStore(state => state.snapToCornersAndEdges)
  const snapToAngles = useControlStore(state => state.snapToAngles)

  const setActiveElement = useEditElementStore(state => state.setActiveElement)
  const addBeam = useModelStore(state => state.addBeam)

  const snapTargets = useModelSnapTargets({ xyOnly: true })

  const tapelineSnapTargets = useTapelineSnapTargets()
  const tapelineCenterTargets = useTapelineCentersSnapTargets()

  const isDrawnWallSegment = (object?: Object3D): boolean => {
    if (!object) return false
    return (Object.values(BeamElements) as string[]).includes(object.name)
  }

  const isValidDrawTarget = (object: Object3D): boolean => {
    let isValidTarget = !isDrawnWallSegment(object)

    const { current: tscurr } = transientWallState

    // exclude the active line in case we are creating or updating a line
    if ((tscurr.isCreating || tscurr.isUpdating) && tscurr.lineRef?.current) {
      isValidTarget =
        isValidTarget &&
        object !== tscurr.lineRef.current.startHandleRef.current &&
        object !== tscurr.lineRef.current.endHandleRef.current
    }

    return isValidTarget
  }

  const onDrawStart = (transientDrawState: TransientDrawState) => {
    // start drawing in case the wall and a draw point are available and no
    // other handle is active
    if (transientDrawState.drawPoint && lineRef.current && !transientWallState.current.lineRef) {
      const drawStartSnapGuid = transientDrawState.snappedPointTarget?.metaData?.guid as
        | undefined
        | string

      // in order to prevent zero-width lines on every mousedown, we only
      // prepare the state but do not start drawing. setHandleAndStartDragging()
      // gets triggered inside mousemove based on the state set here
      transientWallState.current = {
        isCreating: true,
        isFirstMove: true,
        lineRef: lineRef,
        drawStartSnapGuid,
      }

      // the angle snapping origin needs to be defined outside of the draw
      // controller to allow for flexibility (see onLineEditStart)
      const { drawPoint, drawTarget } = transientDrawState

      if (drawPoint && drawTarget) {
        // define the plane angular snapping needs to work inside
        const snappingNormals = getOrientedWorldNormalsForIntersection(drawTarget, true)

        if (snappingNormals) {
          drawControllerRef.current?.setAngleSnappingOrigin({
            origin: drawPoint,
            ...snappingNormals,
          })
        }
      }
    }
  }

  const onDrawMouseMove = (transientDrawState: TransientDrawState) => {
    const { validIntersections, drawPoint, angleSnappingOrigin } = transientDrawState
    const { current: tscurr } = transientWallState

    // new variable assignment so the draw point can be overridden
    // use the angle snapping origin here in case the mouse has moved since
    // onDrawStart
    let actualDrawPoint = tscurr.isFirstMove ? angleSnappingOrigin?.origin : drawPoint

    // check if the mouse intersects a wall handle
    const intersectedWallHandle = validIntersections?.find(
      intersection => intersection.object.name === BeamElements.Handle,
    )

    // hide the indicator and move the draw point to the handle if yes
    if (intersectedWallHandle) {
      if (enableIndicator) setEnableIndicator(false)
      actualDrawPoint = toImmutable(intersectedWallHandle.object.position.clone())
    } else {
      if (!enableIndicator) setEnableIndicator(true)
    }

    if (actualDrawPoint && (tscurr.isCreating || tscurr.isUpdating) && tscurr.lineRef?.current) {
      // executed on the first mousemove after line creation was started
      if (tscurr.isCreating && tscurr.isFirstMove) {
        tscurr.lineRef.current.setHandleAndStartDragging(
          LineHandles.End,
          actualDrawPoint,
          actualDrawPoint,
        )
        tscurr.isFirstMove = false
        setShowTempLine(true)
      }

      tscurr.lineRef.current.updateActiveHandle(actualDrawPoint)
    }
  }

  const onDrawEnd = () => {
    setShowTempLine(false)

    const { current: tscurr } = transientWallState

    if (
      (tscurr.isCreating || tscurr.isUpdating) &&
      tscurr.lineRef?.current &&
      // check for active handle to avoid processing mouse up events that were
      // not related to a draw action
      tscurr.lineRef?.current.activeHandle &&
      !!slabElevation
    ) {
      const line = tscurr.lineRef.current.stopDraggingAndGetLine()

      if (line.start.distanceTo(line.end) >= minBeamLength) {
        const start = new ImmutableVector3(line.start.x, line.start.y, slabElevation.upper)
        const end = new ImmutableVector3(line.end.x, line.end.y, slabElevation.upper)

        const beam = {
          guid: uuid(),
          storey,
          shape: {
            start,
            end,
          },
          is_local: true,
          coordinate_system: getLineCoordinateSystem(start, end),
          type: 'beams' as ElementTypes,
        }
        setActiveElement(beam.guid)
        addBeam(beam)
      }
    }

    transientWallState.current = {}
  }

  useStructuralPlanningDrawerEsc(() => {
    if (showTempLine) {
      setShowTempLine(false)
      drawControllerRef.current?.abortDrawing()
      drawControllerRef.current?.setAngleSnappingOrigin(undefined)
      transientWallState.current = {}
    }
  }, showTempLine)

  // EFFECTS
  useDefault2DSnapConfigForElementType(SnapTargetElementType.BEAMS)

  return (
    <>
      <DrawController
        ref={drawControllerRef}
        // in case slab elevation is undefined the roof storey is selected
        enabled={!isTapelineActive && enabled && !!slabElevation}
        snapToCornersAndEdges={snapToCornersAndEdges}
        snapToAngles={snapToAngles}
        enableIndicator={enableIndicator}
        color={scenePalette.elements3d.vertical_slabs as string}
        xyOnly
        additionalSnapTargets={[...snapTargets, ...tapelineSnapTargets, ...tapelineCenterTargets]}
        onDrawStart={onDrawStart}
        onMouseMove={onDrawMouseMove}
        onDrawEnd={onDrawEnd}
        isValidDrawTarget={isValidDrawTarget}
        allowNegative={true}
        snapAngle={5}
        orthoSnap={snapOrthogonal}
        indicatorType="crosshair"
      />
      <DraggableLine
        ref={lineRef}
        line={{
          start: new ImmutableVector3(-100, -100, -100),
          end: new ImmutableVector3(-100, -100, -100),
        }}
        lineName={BeamElements.Line}
        handleName={BeamElements.Handle}
        isVisible={showTempLine}
        color={scenePalette.elements3d.vertical_slabs as string}
        showIndicators={false}
      />
    </>
  )
}

export default BeamDrawer2D
