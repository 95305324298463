import React, { ReactElement, useMemo } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { AxiosError } from 'axios'
import { find } from 'lodash-es'
import { useSnackbar } from 'notistack'
import { Stack } from '@mui/material'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'
import { SaveButton } from '@ui/actions'
import { ErrorField, Form } from '@ui/forms'
import { useModelStore } from '@editorStores'
import { getElementCrossSectionAssignment, getModel } from '@queries'
import { updateLintel } from '@mutations'
import { errors } from 'src/constants'
import FormFields from './components/FormFields'
import { createLintelSchema } from './schema'

interface Props {
  positionGuid: string
}

export const WallLintelForm = ({ positionGuid }: Props): ReactElement => {
  const { projectId } = useParams()

  const { enqueueSnackbar } = useSnackbar()
  const client = useQueryClient()

  const lintels = useModelStore(state => state.model.lintels)

  const lintel = useMemo(
    () => find(lintels, { position_guid: positionGuid }),
    [lintels, positionGuid],
  )

  const { walls } = useModelStore(state => state.model)
  const wall = useMemo(() => {
    if (lintel?.wall_guid) {
      return find(walls, { guid: lintel?.wall_guid })
    }
  }, [lintel, walls]) as ShapeObject

  const wallLength = useMemo(() => wall?.shape.points[0].distanceTo(wall?.shape.points[1]), [wall])

  const { schema, defaultValues } = useMemo(() => {
    const schema = createLintelSchema(positionGuid, lintel?.wall_guid as string)

    const distanceToEndOfLintel = wall.shape.points[0].distanceTo(
      new ImmutableVector3(lintel?.end.x, lintel?.end.y, wall.shape.points[0].z),
    )
    const distanceToStartOfLintel = wall.shape.points[0].distanceTo(
      new ImmutableVector3(lintel?.start.x, lintel?.start.y, wall.shape.points[0].z),
    )

    const defaultValues = {
      ...schema.getDefault(),
      relative_start: distanceToStartOfLintel / wallLength,
      relative_end: distanceToEndOfLintel / wallLength,
    }

    return { schema, defaultValues }
  }, [wall, lintel])

  const { mutateAsync, isLoading } = useMutation(
    async ({
      relative_start,
      relative_end,
      guid,
    }: {
      relative_start: number
      relative_end: number
      guid: string
    }) => {
      const absoluteStart = relative_start * wallLength
      const absoluteEnd = relative_end * wallLength
      const roundedAbsoluteStart = Math.round(absoluteStart * 100) / 100
      const roundedAbsoluteEnd = Math.round(absoluteEnd * 100) / 100
      const wallLintelRequest = {
        position_guid: guid,
        lower: roundedAbsoluteStart,
        upper: roundedAbsoluteEnd,
      } as WallLintelRequest

      await updateLintel.request(projectId, wallLintelRequest)
    },
    {
      onSuccess: async () => {
        await Promise.all([
          client.invalidateQueries(getModel.getKey(projectId)),
          client.invalidateQueries(getElementCrossSectionAssignment.getKey(projectId)),
        ])
        client.invalidateQueries(['results'])
        enqueueSnackbar('Sturz erfolgreich gespeichert', { variant: 'success' })
      },
      onError: (error: AxiosError) => {
        if (errors[error.response?.data.code]) {
          enqueueSnackbar(errors[error.response?.data.code], { variant: 'error' })
          return
        }
        enqueueSnackbar('Fehler beim Speichern des Sturzes', { variant: 'error' })
      },
    },
  )

  const onSubmit = (data: { relative_start: number; relative_end: number; guid: string }) => {
    mutateAsync(data)
  }

  return (
    <>
      <Form
        key={positionGuid}
        onSubmit={onSubmit}
        validationSchema={schema}
        defaultValues={defaultValues}
        validationContext={{ wall, lintels }}
      >
        <Stack direction="column" spacing={2}>
          <FormFields guid={positionGuid} length={wallLength} />
          <ErrorField name="isIntersecting" />
          <ErrorField name="isOverlapping" />

          <Stack direction="row" justifyContent="end" spacing={1}>
            <SaveButton type="submit" fullWidth loading={isLoading}>
              Speichern
            </SaveButton>
          </Stack>
        </Stack>
      </Form>
    </>
  )
}
