import { boolean, number, object } from 'yup'

export const globalFireSettingsSchema = object().shape({
  hot_dimensioning_settings: object().shape({
    make_hot_dimensioning_checks: boolean(),
    fire_duration: number()
      .nullable()
      .when('make_hot_dimensioning_checks', {
        is: true,
        then: schema => schema.required('Feuerwiderstandsklasse ist erforderlich.'),
      }),
  }),
  fire_protection_settings: boolean(),
})
