import { materialTypeFromCS } from 'src/components/manager/element_cs/ElementCSFormFields/utils'
import { defaultCrossSectionFormValues } from '../../schema'

export const getDefaultValues = (defaultCS?: CrossSection) => {
  if (defaultCS && defaultCS.shape.kind_literal === `SteelCS`) {
    const new_defaults = {
      crossSection: {
        ...defaultCrossSectionFormValues(),
        steelShape: defaultCS.shape,
        material: defaultCS.material,
        materialType: defaultCS ? materialTypeFromCS(defaultCS) : 'softwoodMaterial',
      },
    }
    return new_defaults
  } else {
    const new_defaults = {
      crossSection: {
        ...defaultCrossSectionFormValues(),
        ...defaultCS,
        materialType: defaultCS ? materialTypeFromCS(defaultCS) : 'softwoodMaterial',
      },
    }
    return new_defaults
  }
}
