import React, { ReactElement } from 'react'
import { UnitField, UnitFieldProps } from '../UnitField'

type CentimeterFieldProps = Omit<
  UnitFieldProps,
  'internalUnit' | 'displayUnit' | 'numeralFormatter'
>

const CentimeterField = ({ ...textFieldProps }: CentimeterFieldProps): ReactElement => {
  return (
    <UnitField {...textFieldProps} internalUnit="m" displayUnit="cm" numeralFormatter="0.[0]" />
  )
}

export default CentimeterField
