import React, { ReactElement, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { getPlaneFromShape } from '@editorUtils'
import { useTapelineSnapTargets } from '@scene'
import { find } from 'lodash-es'
import { Vector3 } from 'three'
import { v4 as uuid } from 'uuid'
import {
  DraggableRectangle,
  DraggableRectangleRef,
} from '@modugen/scene/lib/components/DraggableRectangle/DraggableRectangle'
import { RectangleHandles } from '@modugen/scene/lib/components/DraggableRectangle/types'
import {
  DrawController,
  DrawControllerRef,
  TransientDrawState,
} from '@modugen/scene/lib/controllers/DrawController'
import { useTapelineStore } from '@modugen/scene/lib/controllers/TapelineController/tapelineStore'
import useTapelineCentersSnapTargets from '@modugen/scene/lib/hooks/useTapelineCentersSnapTargets'
import { RectangleXYZ } from '@modugen/scene/lib/types'
import { toImmutable } from '@modugen/scene/lib/utils'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'
import { useEditElementStore, useControlStore, useModelStore } from '@editorStores'
import { useStructuralPlanningDrawerEsc } from '@structuralPlanningHooks'

const minOpeningSize = 0.5

interface Props {
  wallGuid: string
  openingGuid: string | null
}

const OpeningsDrawer = ({ wallGuid, openingGuid }: Props): ReactElement => {
  const draggableRectangleRef = useRef<DraggableRectangleRef>(null)
  const drawControllerRef = useRef<DrawControllerRef>(null)

  const setSnappingFeaturesEnabled = useControlStore(state => state.setSnappingFeaturesEnabled)
  const setIsDrawingActive = useControlStore(state => state.setIsDrawingActive)
  const snapToCornersAndEdges = useControlStore(state => state.snapToCornersAndEdges)

  const setActiveOpening = useEditElementStore(state => state.setActiveOpening)

  const { walls } = useModelStore(state => state.model)
  const addOpening = useModelStore(state => state.addOpening)
  const removeOpening = useModelStore(state => state.removeOpening)

  const tapelineTargets = useTapelineSnapTargets()
  const tapelineCenterTargets = useTapelineCentersSnapTargets()
  const snapTargets = useMemo(
    () => [...tapelineTargets, ...tapelineCenterTargets],
    [tapelineTargets, tapelineCenterTargets],
  )
  const isTapelineActive = useTapelineStore(state => state.isActive)

  const [isDrawing, setIsDrawing] = useState(false)

  // MEMOS

  const wall = useMemo(() => find(walls, { guid: wallGuid }), [wallGuid, walls]) as ShapeObject

  const wallShape = wall?.shape.points as RectangleXYZ

  const wallWidthDirection = useMemo(() => wallShape[1].sub(wallShape[0]).normalize(), [wallShape])
  const wallHeightDirection = useMemo(() => new ImmutableVector3(0, 0, 1), [wallShape])

  // EFFECTS

  useLayoutEffect(() => {
    setIsDrawingActive(true)

    return () => {
      setIsDrawingActive(false)
    }
  }, [])

  useEffect(() => {
    setSnappingFeaturesEnabled({
      snapOrthogonal: false,
      snapToAngle: true,
      snapToCornersAndEdges: true,
      snapToTapelineCenters: true,
    })
    return () => {
      setSnappingFeaturesEnabled({
        snapOrthogonal: true,
        snapToCornersAndEdges: true,
        snapToAngle: true,
        snapToTapelineCenters: true,
      })
    }
  })

  useEffect(() => {
    return () => {
      const walls = useModelStore.getState().model.walls
      const wall = find(walls, { guid: wallGuid })
      const opening = find(wall?.openings, { guid: openingGuid }) as Opening | undefined
      if (opening?.is_local) {
        removeOpening(wallGuid, opening.guid)
      }
    }
  }, [wallGuid, openingGuid])

  // HOTKEY

  useStructuralPlanningDrawerEsc(() => {
    if (isDrawing) {
      drawControllerRef.current?.abortDrawing()
      if (draggableRectangleRef.current) {
        draggableRectangleRef.current.stopDraggingAndGetPoints()

        // not so nice way to reset the draggable rectangle to it's defaults

        const defaultPoint = new ImmutableVector3(0, 0, -1000)
        draggableRectangleRef.current.setHandleAndStartDragging(
          RectangleHandles.Start,
          defaultPoint,
        )
        draggableRectangleRef.current.stopDraggingAndGetPoints()
        draggableRectangleRef.current.setHandleAndStartDragging(RectangleHandles.End, defaultPoint)
        draggableRectangleRef.current.stopDraggingAndGetPoints()
      }
      setIsDrawing(false)
    } else if (openingGuid) {
      setActiveOpening(null)
    }
  }, isDrawing || !!openingGuid)

  // EVENTS

  const onDrawStart = (state: TransientDrawState) => {
    const { drawPoint } = state

    if (!drawPoint) return

    draggableRectangleRef.current?.setHandleAndStartDragging(RectangleHandles.End, state.drawPoint)
    setIsDrawing(true)
  }

  const onDrawMouseMove = (state: TransientDrawState) => {
    const { drawPoint } = state

    if (!drawPoint || !draggableRectangleRef.current?.activeHandle) return

    draggableRectangleRef.current?.updateActiveHandle(
      drawPoint,
      wallWidthDirection,
      wallHeightDirection,
    )
  }

  const onDrawEnd = () => {
    setIsDrawing(false)

    if (!draggableRectangleRef.current?.activeHandle) return

    const points = draggableRectangleRef.current?.stopDraggingAndGetPoints() as RectangleXYZ

    const size = points[0].distanceTo(points[2])

    const wallPlane = getPlaneFromShape(wallShape)

    const projectedOpeningPoints = points.map(p =>
      toImmutable(wallPlane.projectPoint(p.v, new Vector3())),
    )

    if (size > minOpeningSize) {
      const id = uuid()
      const newOpening: Opening = {
        guid: id,
        shape: {
          guid: id,
          points: projectedOpeningPoints,
        },
        is_local: true,
      }

      addOpening(wall.guid, newOpening)
      setActiveOpening(id)
    }
  }

  return (
    <>
      <DrawController
        ref={drawControllerRef}
        enabled={!isTapelineActive}
        enableIndicator
        onDrawStart={onDrawStart}
        onMouseMove={onDrawMouseMove}
        onDrawEnd={onDrawEnd}
        color="blue"
        additionalSnapTargets={snapTargets}
        snapToCornersAndEdges={snapToCornersAndEdges}
        isValidDrawTarget={object => object.userData.type === 'wall'}
      />

      <DraggableRectangle
        color="blue"
        ref={draggableRectangleRef}
        points={[
          new ImmutableVector3(0, 0, -1000),
          new ImmutableVector3(0, 0, -1000),
          new ImmutableVector3(0, 0, -1000),
          new ImmutableVector3(0, 0, -1000),
        ]}
        rectangleProps={{
          nonSelectable: true,
        }}
      />
    </>
  )
}

export default OpeningsDrawer
