import React from 'react'
import AccordionWithHiddenExpandedSummary from '@ui/structure/AccordionWithHiddenExpandedSummary'
import SupportResultsTable from './components/SupportResultsTable'

interface Props {
  designForces: DesignSupportForce[]
  defaultExpanded?: boolean
}

const SupportResults = ({ designForces, defaultExpanded = false }: Props) => {
  return (
    <AccordionWithHiddenExpandedSummary
      title="Design Auflagerkräfte"
      defaultExpanded={defaultExpanded}
      data-cy="checks-accordion"
    >
      <SupportResultsTable designForces={designForces} />
    </AccordionWithHiddenExpandedSummary>
  )
}

export default SupportResults
