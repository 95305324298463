import { useMemo } from 'react'
import { memoize, reduce } from 'lodash-es'
import { useAllDomains } from '@editorHooks'

const getDomains = memoize((allDomains: Domain[]) =>
  reduce(
    allDomains,
    (col, domain) => ({
      ...col,
      [domain.guid]: domain,
    }),
    {} as Record<string, Domain>,
  ),
)

const useGuidToDomain = () => {
  const allDomains = useAllDomains()

  const guidToDomain = useMemo(() => getDomains(allDomains), [allDomains])

  return guidToDomain
}

export default useGuidToDomain
