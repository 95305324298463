import { useCallback, useMemo } from 'react'
import { useHotkeys, useHotkeysContext } from 'react-hotkeys-hook'
import { some } from 'lodash-es'
import { useSnackbar } from 'notistack'
import { useCameraStore, useTapelineStore } from '@modugen/scene/lib'
import { useControlStore, useElementLoadStore, useStructuralPlanningStore } from '@editorStores'
import { useSelectionMode } from '@editorHooks'
import { useStructuralPlanningQueryParams } from '@structuralPlanningHooks'
import { STRUCTURAL_PLANNING_DRAWER_ESC_SCOPE_PREFIX } from './useStructuralPlanningDrawerEsc'
import { STRUCTURAL_PLANNING_FORM_ESC_SCOPE_PREFIX } from './useStructuralPlanningFormEsc'

const useStructuralPlanningEsc = () => {
  const isTapelineDrawing = useTapelineStore(state => state.isDrawing)
  const actionMode = useControlStore(state => state.actionMode)
  const deselectAllIds = useStructuralPlanningStore(state => state.deselectAllIds)
  const selectedIds = useStructuralPlanningStore(state => state.selectedIds)

  const { enabledScopes } = useHotkeysContext()

  const loads = useElementLoadStore(state => state.loads)

  const {
    params: { mode, storey, selectedElements },
    actions: { reset, setStorey, toggleMode, resetElements },
    modes: {
      isStiffeningSelectionMode,
      isDrawMode,

      isLoadMode,
    },
  } = useStructuralPlanningQueryParams()

  const { isSelectionMode: isSnackbarSelection, unsetSelectionMode } = useSelectionMode()
  const { enqueueSnackbar } = useSnackbar()

  const isFloorplan = useCameraStore(state => state.isOrthographic)
  const is3D = !isFloorplan
  const queryElementsSelected = !!selectedElements?.length
  const hasLocalLoads = useMemo(() => some(loads, 'isLocal'), [loads])
  const resetQueryElementsSelected = useCallback(() => resetElements(), [resetElements])

  const handleStiffeningModeEsc = useCallback(() => {
    if (selectedIds.size) deselectAllIds()
    else reset()
  }, [deselectAllIds, reset, selectedIds])

  const handleLoadModeEsc = useCallback(() => {
    if (hasLocalLoads) {
      enqueueSnackbar('Noch nicht alle Lasten gespeichert, bitte zuerst speichern oder entfernen', {
        variant: 'info',
      })
    } else if (queryElementsSelected) {
      resetQueryElementsSelected()
    } else {
      reset()
    }
  }, [enqueueSnackbar, hasLocalLoads, queryElementsSelected, reset, resetQueryElementsSelected])

  const handle3DNonDrawModeEsc = useCallback(() => {
    if (queryElementsSelected) {
      reset()
    } else {
      reset()
    }
  }, [queryElementsSelected, reset])

  const handle3DDrawModeEsc = useCallback(() => {
    reset()
  }, [reset])

  const handleOrthographicEsc = useCallback(() => {
    if (mode) toggleMode()
    else if (storey) setStorey(null)
  }, [mode, setStorey, storey, toggleMode])

  const handle3dEsc = useCallback(() => {
    // In this callback we handle the 4 big groups of actions in structural
    // planning view. Stiffening selection, loads, and drawing + general
    // selection mode. Stiffening selection and loads are only enabled in 3d,
    // hence we handle it here. All other esc actions are handled in the form
    // and drawer components itself.
    if (isStiffeningSelectionMode) handleStiffeningModeEsc()
    else if (isLoadMode) handleLoadModeEsc()
    else if (!isDrawMode) handle3DNonDrawModeEsc()
    else if (isDrawMode) handle3DDrawModeEsc()
  }, [
    isStiffeningSelectionMode,
    handleStiffeningModeEsc,
    isLoadMode,
    handleLoadModeEsc,
    isDrawMode,
    handle3DNonDrawModeEsc,
    handle3DDrawModeEsc,
  ])

  // ESCAPE KEY ACTIONS

  const escKeyPress = useCallback(() => {
    if (isSnackbarSelection) {
      unsetSelectionMode()
    } else if (isFloorplan) {
      handleOrthographicEsc()
    } else if (is3D) {
      handle3dEsc()
    }
  }, [
    handle3dEsc,
    handleOrthographicEsc,
    is3D,
    isFloorplan,
    isSnackbarSelection,
    unsetSelectionMode,
  ])

  const drawerScopeEnabled = useMemo(
    () =>
      some(enabledScopes, scope => scope.startsWith(STRUCTURAL_PLANNING_DRAWER_ESC_SCOPE_PREFIX)),
    [enabledScopes],
  )

  const formScopeEnabled = useMemo(
    () => some(enabledScopes, scope => scope.startsWith(STRUCTURAL_PLANNING_FORM_ESC_SCOPE_PREFIX)),
    [enabledScopes],
  )

  useHotkeys(
    'esc',
    escKeyPress,
    {
      enabled:
        !isTapelineDrawing && actionMode !== 'hide' && !drawerScopeEnabled && !formScopeEnabled,
    },
    [escKeyPress],
  )
}

export default useStructuralPlanningEsc
