import React, { ReactElement, useEffect, useMemo } from 'react'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import { mapValueKey } from '@editorUtils'
import { MemberCheckResults } from '@resultsComponents'
import { useElementPositionType, useResultsQueryParams } from '@resultsHooks'
import { AxiosError } from 'axios'
import { filter, find } from 'lodash-es'
import { useSnackbar } from 'notistack'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import {
  Tab,
  Stack,
  Typography,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material'
import { Box } from '@ui/structure'
import { useModelStore, useResultsStore } from '@editorStores'
import { useAllDomains, useElementLabel, useElementTypes } from '@editorHooks'
import { savePositionGrouping, smoothLoads } from '@mutations'
import { memberToDomain } from 'src/components/pages/Editor/utils/positionToDomain'
import { buildErrorMessage } from 'src/constants/errors'
import { useEnhanceLoadsWithDomain } from '../../hooks/useEnhanceLoadsWithDomain'
import { SlabBeamForm } from '../SlabBeamForm'
import SupportResults from '../SupportResults'
import { ExportSettingsForm, LoadResults } from './components'
import { SystemForm } from './components/SystemEditForm'
import { elementTypeLabelPrefixLookup, LocalTab, tabConfig } from './constants'
import useIdsOfGroup from './hooks/useIdsOfGroup'

interface Props {
  selectedElement: string
}

const LocalResultsQueryParamWrapper = (): ReactElement | null => {
  const {
    params: { selectedElement },
  } = useResultsQueryParams()

  if (!selectedElement) return null

  return <LocalResults selectedElement={selectedElement} />
}

const LocalResults = ({ selectedElement }: Props): ReactElement => {
  const { projectId } = useParams()

  const { enqueueSnackbar } = useSnackbar()

  const {
    params: { localTab },
    actions: { setLocalTab, selectPosition, setIsLocalMode },
  } = useResultsQueryParams()

  const getElementLabel = useElementLabel()

  // STORE

  const verticalTransmissionGraph = useResultsStore(state => state.verticalTransmissionGraph)

  const setWallRipsPositionGrouping = useResultsStore(state => state.setWallRipsPositionGrouping)
  const setWallLintelsPositionGrouping = useResultsStore(
    state => state.setWallLintelsPositionGrouping,
  )
  const setSlabBeamsPositionGrouping = useResultsStore(state => state.setSlabBeamsPositionGrouping)
  const setRoofSlabBeamsPositionGrouping = useResultsStore(
    state => state.setRoofSlabBeamsPositionGrouping,
  )
  const setBeamsPositionGrouping = useResultsStore(state => state.setBeamsPositionGrouping)
  const setPurlinsPositionGrouping = useResultsStore(state => state.setPurlinsPositionGrouping)
  const setColumnsPositionGrouping = useResultsStore(state => state.setColumnsPositionGrouping)

  const setPreviewLoads = useResultsStore(state => state.setPreviewLoads)
  const clearHiddenLoads = useResultsStore(state => state.clearHiddenLoads)

  const rips = useModelStore(state => state.model.rips)
  const lintels = useModelStore(state => state.model.lintels)

  const wallRipsPositionGrouping = useResultsStore(state => state.wallRipsPositionGrouping)
  const designForces = useResultsStore(state => state.designForces)
  const wallLintelsPositionGrouping = useResultsStore(state => state.wallLintelsPositionGrouping)
  const slabBeamsPositionGrouping = useResultsStore(state => state.slabBeamsPositionGrouping)
  const columnsPositionGrouping = useResultsStore(state => state.columnsPositionGrouping)
  const purlinsPositionGrouping = useResultsStore(state => state.purlinsPositionGrouping)
  const roofSlabBeamsPositionGrouping = useResultsStore(
    state => state.roofSlabBeamsPositionGrouping,
  )
  const beamsPositionGrouping = useResultsStore(state => state.beamsPositionGrouping)

  const domains = useAllDomains()
  const model = useModelStore(state => state.model)

  const verticalSlabs = useModelStore(state => state.model.vertical_slabs)
  const verticalRoofSlabs = useModelStore(state => state.model.vertical_roof_slabs)

  const structuralChecks = useResultsStore(state => state.structuralChecks)

  const setShowPreviewLoads = useResultsStore(state => state.setShowPreviewLoads)

  const standardRipChecks = useResultsStore(state => state.standardRipChecks)

  const elementLoads = useResultsStore(state => state.elementLoads)
  const positionLoads = useResultsStore(state => state.positionLoads)

  const settings = useResultsStore(state => state.memberCheckSettings)

  const settingsOnMember: SettingsOnMember | null = useMemo(
    () => find(settings, { element_guid: selectedElement }) || null,
    [settings, selectedElement],
  )

  const elementTypesWithoutStandardRips = useElementTypes()
  const elementTypesWithStandardRips: Record<string, ElementTypes | undefined> = useMemo(() => {
    if (!standardRipChecks) return elementTypesWithoutStandardRips

    const reducedStandardRips = standardRipChecks.reduce((collector, element) => {
      collector[element.standard_rip_member.guid] = 'standard_rips'
      return collector
    }, {} as Record<string, ElementTypes | undefined>)

    return { ...elementTypesWithoutStandardRips, ...reducedStandardRips }
  }, [elementTypesWithoutStandardRips, standardRipChecks])

  const elementType = useMemo(() => {
    if (!selectedElement) return undefined

    return elementTypesWithStandardRips[selectedElement]
  }, [elementTypesWithStandardRips, selectedElement])

  // DATA TRANSFORMATIONS

  const loads = useMemo(
    () => [...(elementLoads || []), ...(positionLoads || [])],
    [elementLoads, positionLoads],
  )

  const idsOfGroup = useIdsOfGroup()

  const selectedPositionType = useElementPositionType(selectedElement || undefined)

  const allPositions = useMemo(() => rips && lintels && [...rips, ...lintels], [rips, lintels])

  const slabBeams = useMemo(
    () =>
      verticalSlabs && verticalRoofSlabs
        ? [...verticalSlabs, ...verticalRoofSlabs].map(slab => slab.beam)
        : [],
    [verticalSlabs, verticalRoofSlabs],
  )

  const allLoadsWithDomain = useEnhanceLoadsWithDomain({
    loads,
    domains,
    verticalTransmissionGraph,
    slabBeams,
  })

  const guidToPosition = useMemo(
    () => allPositions && mapValueKey(allPositions, 'position_guid'),
    [allPositions],
  )

  const slabBeamGuidToBeam = useMemo(() => slabBeams && mapValueKey(slabBeams), [slabBeams])

  const isSlabBeam = elementType === 'slab_beams'

  const selectedStandardRipLoads = useMemo(() => {
    const selectedCheck = find(standardRipChecks, check => {
      return check.standard_rip_member.guid === selectedElement
    })

    if (!selectedCheck) return []

    const standardRipDomain = memberToDomain(selectedCheck.standard_rip_member)

    return selectedCheck.standard_rip_loads.map(load => {
      return {
        ...load,
        domain: standardRipDomain,
      } as PointLoadWithDomain
    })
  }, [standardRipChecks, selectedElement])

  const filteredLoads = useMemo(() => {
    // This will have length if a standard-rip is selected, so we display those
    // loads instead
    if (selectedStandardRipLoads.length > 0) return selectedStandardRipLoads

    if (!guidToPosition) return

    const position = selectedElement
      ? guidToPosition[selectedElement] || slabBeamGuidToBeam[selectedElement]
      : undefined
    const elementGuid =
      position && position.position_guid ? position.position_guid : selectedElement

    return filter(allLoadsWithDomain, load => load.element_guid === elementGuid)
  }, [
    selectedStandardRipLoads,
    guidToPosition,
    selectedElement,
    slabBeamGuidToBeam,
    allLoadsWithDomain,
  ]) as (AreaLoad | DomainLoad)[]

  const checksOfPosition = useMemo(
    () => (structuralChecks ? filter(structuralChecks, { element_guid: selectedElement }) : []),
    [structuralChecks, selectedElement],
  )

  const groupingOfSelectedElement = useMemo(() => {
    if (selectedPositionType === 'wall-lintels') return wallLintelsPositionGrouping
    if (selectedPositionType === 'wall-rips') return wallRipsPositionGrouping
    if (selectedPositionType === 'slab-beams') return slabBeamsPositionGrouping
    if (selectedPositionType === 'roof-slab-beams') return roofSlabBeamsPositionGrouping
    if (selectedPositionType === 'purlins') return purlinsPositionGrouping
    if (selectedPositionType === 'columns') return columnsPositionGrouping
    if (selectedPositionType === 'beams') return beamsPositionGrouping
  }, [
    wallRipsPositionGrouping,
    wallLintelsPositionGrouping,
    slabBeamsPositionGrouping,
    columnsPositionGrouping,
    purlinsPositionGrouping,
    beamsPositionGrouping,
    roofSlabBeamsPositionGrouping,
    selectedPositionType,
  ])

  // used for preview loads

  const modelGuidToPosition = useMemo(
    () => mapValueKey([...model.purlins, ...model.beams, ...model.columns]),
    [model],
  )

  const getLabel = (guid: string): string => {
    if (!elementType) {
      return ''
    }
    if (elementType === 'standard_rips') {
      const selectedCheck = find(standardRipChecks, check => {
        return check.standard_rip_member.guid === selectedElement
      }) as StandardRipCheck
      const labelFromElement = getElementLabel(selectedCheck.wall_guid)
      const labelPrefix = elementTypeLabelPrefixLookup[elementType]
      return `${labelPrefix} - ${labelFromElement}`
    } else {
      const labelFromElement = getElementLabel(guid)
      const labelPrefix = elementTypeLabelPrefixLookup[elementType]
      return `${labelPrefix} - ${labelFromElement}`
    }
  }

  const label = getLabel(selectedElement)

  // MUTATIONS

  const { mutate, isLoading } = useMutation(
    async ({
      data,
      positionType,
    }: {
      data: MemberPositionBundle[]
      positionType: PositionGroupingType
    }) => {
      return {
        data: await savePositionGrouping.request(projectId as string, positionType, data),
        positionType,
      }
    },
    {
      onSuccess: async ({ data, positionType }) => {
        switch (positionType) {
          case 'wall-lintels':
            setWallLintelsPositionGrouping(data)
            break
          case 'wall-rips':
            setWallRipsPositionGrouping(data)
            break
          case 'slab-beams':
            setSlabBeamsPositionGrouping(data)
            break
          case 'roof-slab-beams':
            setRoofSlabBeamsPositionGrouping(data)
            break
          case 'beams':
            setBeamsPositionGrouping(data)
            break
          case 'purlins':
            setPurlinsPositionGrouping(data)
            break
          case 'columns':
            setColumnsPositionGrouping(data)
            break
        }

        enqueueSnackbar('Konfiguration gespeichert', { variant: 'success' })
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(buildErrorMessage(error, 'Fehler beim Speichern der Konfiguration'), {
          variant: 'error',
        })
      },
    },
  )

  const { isLoading: isLoadingPreview, mutate: calculatePreview } = useMutation(
    async (memberSettings: MemberSmoothingSettings) => {
      const modelPosition = modelGuidToPosition[selectedElement]
      if (modelPosition) {
        const { start, end } = modelPosition.shape
        const length = start.distanceTo(end)

        const filteredLoads = filter(loads, ['element_guid', selectedElement])
        const data: {
          settings: SmoothingSettings
          loads: ElementLoad[]
        } = {
          settings: { ...memberSettings, reference_length: length },
          loads: filteredLoads,
        }
        return smoothLoads.request(projectId as string, data)
      } else {
        const position = guidToPosition?.[selectedElement]
        const slabBeam = slabBeamGuidToBeam?.[selectedElement]
        if (position) {
          const { start, end } = position
          const length = start.distanceTo(end)
          const filteredLoads = filter(loads, ['element_guid', position.position_guid])

          const data: {
            settings: SmoothingSettings
            loads: ElementLoad[]
          } = {
            settings: { ...memberSettings, reference_length: length },
            loads: filteredLoads,
          }
          return smoothLoads.request(projectId as string, data)
        }

        if (slabBeam) {
          const { start, end } = slabBeam.shape
          const length = start.distanceTo(end)
          const filteredLoads = filter(loads, ['element_guid', slabBeam.guid])

          const data: {
            settings: SmoothingSettings
            loads: ElementLoad[]
          } = {
            settings: { ...memberSettings, reference_length: length },
            loads: filteredLoads,
          }
          return smoothLoads.request(projectId as string, data)
        }
      }
    },
    {
      onSuccess: async (data: ElementLoad[]) => {
        setPreviewLoads(data)
        setShowPreviewLoads(true)
        enqueueSnackbar('Lastenvorschau verfügbar', {
          variant: 'success',
        })
      },
      onError: () => {
        enqueueSnackbar('Fehler beim generieren der Lastenvorschau', {
          variant: 'error',
        })
      },
    },
  )

  // EFFECTS

  useEffect(() => {
    setPreviewLoads(null)
    clearHiddenLoads()
  }, [clearHiddenLoads, selectedElement, setPreviewLoads])

  // COMPONENT LOGIC

  const showExportSettings = groupingOfSelectedElement && selectedPositionType
  const showLoadsSmoothing =
    selectedPositionType === 'beams' ||
    selectedPositionType === 'purlins' ||
    selectedPositionType === 'wall-lintels' ||
    selectedPositionType === 'slab-beams' ||
    selectedPositionType === 'roof-slab-beams'

  const showExportSettingsWarning = useMemo(
    () =>
      showExportSettings && selectedElement && Object.keys(idsOfGroup).includes(selectedElement),
    [showExportSettings, idsOfGroup, selectedElement],
  )

  // CHECKS TAB DISPLAY LOGIC
  const showMemberChecksTable =
    selectedElement &&
    elementType &&
    (
      [
        'beams',
        'columns',
        'purlins',
        'rips',
        'lintels',
        'slab_beams',
        'roof_slab_beams',
        'standard_rips',
      ] as ElementTypes[]
    ).includes(elementType)

  const showLinkToSlabBeam =
    elementType &&
    (['vertical_slabs', 'vertical_roof_slabs'] as ElementTypes[]).includes(elementType)

  const selectedSlab = useMemo(
    () => find([...verticalSlabs, ...verticalRoofSlabs], slab => slab.guid === selectedElement),
    [verticalSlabs, verticalRoofSlabs, selectedElement],
  )

  const elementDesignForces = useMemo(
    () => find(designForces, ['element_guid', selectedElement]),
    [designForces, selectedElement],
  )

  return (
    <Box>
      <TabContext value={localTab || tabConfig.settings.value}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
          }}
        >
          <TabList
            onChange={(_e: unknown, v: LocalTab) => setLocalTab(v)}
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab
              value={tabConfig.settings.value}
              label={tabConfig.settings.label}
              data-cy={`${tabConfig.settings.value}-tab`}
            />
            <Tab
              value={tabConfig.loads.value}
              label={tabConfig.loads.label}
              data-cy={`${tabConfig.loads.value}-tab`}
            />
          </TabList>
        </Box>
        <TabPanel value={tabConfig.loads.value}>
          <LoadResults loads={filteredLoads} />
        </TabPanel>
        <TabPanel value={tabConfig.settings.value}>
          <Stack direction="column" spacing={2}>
            <Typography variant="h5">{label}</Typography>
            {showLinkToSlabBeam && (
              <Button
                variant="outlined"
                onClick={() => {
                  selectedSlab &&
                    setIsLocalMode(true, selectedSlab.beam.guid, tabConfig.settings.value)
                }}
              >
                Position anzeigen
                <ArrowOutwardIcon />
              </Button>
            )}
            <Accordion defaultExpanded data-cy="system-accordion">
              <AccordionSummary
                aria-controls="panel1-content"
                id="panel1-header"
                expandIcon={<ArrowDropDownIcon />}
              >
                <Typography variant="h5" align="center" sx={{ width: '100%' }}>
                  System
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <SystemForm element={selectedElement} />
                {isSlabBeam && <SlabBeamForm slabBeamGuid={selectedElement} />}
              </AccordionDetails>
            </Accordion>

            <SupportResults designForces={elementDesignForces?.design_support_forces || []} />

            {showMemberChecksTable && (
              <>
                <MemberCheckResults
                  checksOfPosition={checksOfPosition}
                  settingsOnMember={settingsOnMember}
                />
              </>
            )}
            {showExportSettings && (
              <>
                <Accordion defaultExpanded data-cy="export-accordion">
                  <AccordionSummary
                    aria-controls="panel1-content"
                    id="panel1-header"
                    expandIcon={<ArrowDropDownIcon />}
                  >
                    <Typography variant="h5" align="center" sx={{ width: '100%' }}>
                      Export
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {showExportSettingsWarning ? (
                      <>
                        <Stack
                          direction="column"
                          display="flex"
                          justifyContent="space-between"
                          alignItems="flex-end"
                          spacing={2}
                        >
                          <Typography>Element ist bereits gruppiert</Typography>
                          <Button
                            variant="contained"
                            onClick={() => selectPosition(idsOfGroup[selectedElement as string])}
                          >
                            Zum repräsentativen Element
                          </Button>
                        </Stack>
                      </>
                    ) : (
                      <>
                        <ExportSettingsForm
                          onSave={data => mutate({ data, positionType: selectedPositionType })}
                          position={selectedElement}
                          settings={groupingOfSelectedElement}
                          isLoading={isLoading}
                          allowLoadsSmoothing={showLoadsSmoothing}
                          isLoadingPreview={isLoadingPreview}
                          onCalculatePreview={calculatePreview}
                          onClickPosition={selectPosition}
                        />
                      </>
                    )}
                  </AccordionDetails>
                </Accordion>
              </>
            )}
          </Stack>
        </TabPanel>
      </TabContext>
    </Box>
  )
}

export default LocalResultsQueryParamWrapper
