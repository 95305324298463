export const memberCheckTypeToReadableMap: Record<MemberCheckType, string> = {
  BendingYZ: 'zweiachsige Biegung',
  BendingY: 'Biegung Y',
  BendingZ: 'Biegung Z',
  BendingWithTension: 'Biegung und Zug',
  BendingWithCompression: 'Biegung und Druck',
  TensionOnly: 'Zug',
  CompressionOnly: 'Druck',
  TwoWayShearStress: 'zweiachsiger Schub',
  ShearStressY: 'Schub Y',
  ShearStressZ: 'Schub Z',
  BendingYZHot: 'zweiachsige Biegung bei Brand',
  BendingWithTensionHot: 'Biegung und Zug bei Brand',
  BendingWithCompressionHot: 'Biegung und Druck bei Brand',
  TensionOnlyHot: 'Zug bei Brand',
  CompressionOnlyHot: 'Druck bei Brand',
  TwoWayShearStressHot: 'zweiachsiger Schub bei Brand',
  HotDimensioning: 'Querschnittsreduzierung bei Brand',
  DisplacementNetFinZ: 'Enddurchbiegung Z',
  DisplacementNetFinY: 'Enddurchbiegung Y',
  DisplacementNetFinYZ: 'zweiachsige Enddurchbiegung',
  DisplacementInstY: 'Durchbiegung Y',
  DisplacementInstZ: 'Durchbiegung Z',
  DisplacementInstYZ: 'Durchbiegung YZ',
  SupportCompression: 'Auflagerpressung',
  Vibration: 'Schwingung',
  SteelBasicCheck: 'Kombiniert',
  SteelDisplacement: 'Durchbiegung',
  SteelCompression: 'Druck/Knicken',
  SteelSupportCompression: 'Auflagerpressung',
  SteelBasePlateWeld: 'Schweißnaht Fußplatte',
}
