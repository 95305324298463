import React, { ReactElement, useEffect, useMemo } from 'react'
import { filter, find, sortBy } from 'lodash-es'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { LoadingButton } from '@mui/lab'
import { AccordionSummary, AccordionDetails, Typography } from '@mui/material'
import { SaveButton } from '@ui/actions'
import { Form, FormAccordion } from '@ui/forms'
import { Box } from '@ui/structure'
import { useStructuralPlanningStore, useModelStore, useElementSelectionStore } from '@editorStores'
import { useStructuralPlanningQueryParams } from '../../hooks'
import TensileTransmitterForm from './TensileTransmitterForm'
import { connectorListSchema } from './schema'

interface ElementSupportItemWithTargets extends ElementSupportItem {
  targets: ElementSupportItem[]
}

interface Props {
  selectedElement: string
  onSave: () => void
  onRecalculate: () => void
  isLoading: boolean
  isRecalculating: boolean
}

const TensileTransmitterList = ({
  selectedElement,
  onSave,
  isLoading,
  isRecalculating,
  onRecalculate,
}: Props): ReactElement => {
  const {
    params: { connectorGuid },
    actions: { setConnector },
  } = useStructuralPlanningQueryParams()
  const stiffeningElements = useStructuralPlanningStore(state => state.mergedProposal)
  const graph = useStructuralPlanningStore(state => state.tensileTransmissionGraph)
  const domains = useModelStore(state => state.domains)
  const walls = useModelStore(state => state.model.walls)

  const setSecondaryHighlightedIds = useElementSelectionStore(
    state => state.setSecondaryHighlightedIds,
  )

  const supports = useMemo(() => {
    const items = graph?.element_supports || []
    const filteredItems = filter(items, ['element_guid', selectedElement])
    return sortBy(filteredItems, 'relative_position')
  }, [selectedElement, graph])

  const defaultValues = useMemo(() => {
    return {
      supports: supports.map(support => {
        const { guid } = support

        const filteredTargets = filter(graph.support_targets, ['support_guid', guid]).map(
          ({ target_guid }) => find(graph.element_targets, ['guid', target_guid]),
        ) as ElementSupportItem[]

        const targets = sortBy(filteredTargets, 'relative_position')

        return {
          ...support,
          targets,
        }
      }),
    }
  }, [graph, supports])

  useEffect(() => {
    if (!connectorGuid && supports.length) {
      const { guid, element_guid } = supports[0]
      setConnector(guid, element_guid)
    }
  }, [supports])

  useEffect(() => {
    const activeSupport = find(defaultValues.supports, ['guid', connectorGuid])

    if (activeSupport) {
      const elementTargets = activeSupport.targets.map(({ element_guid }) => element_guid)
      setSecondaryHighlightedIds(elementTargets)
    }

    return () => setSecondaryHighlightedIds([])
  }, [connectorGuid, defaultValues])

  useEffect(() => {
    const targetGuids = defaultValues.supports.reduce(
      (acc, item: ElementSupportItemWithTargets) => {
        return [...acc, ...item.targets.map(({ element_guid }) => element_guid)]
      },
      [] as string[],
    )

    setSecondaryHighlightedIds(targetGuids)

    return () => setSecondaryHighlightedIds([])
  }, [defaultValues])

  return (
    <Form
      defaultValues={defaultValues}
      validationSchema={connectorListSchema}
      validationContext={{ stiffeningElements, domains, walls }}
      onSubmit={() => onSave()}
      enableReinitialize
    >
      <Box>
        {supports.map((support, index) => {
          const { guid, element_guid } = support

          return (
            <FormAccordion
              fields={`supports.[${index}]`}
              key={guid}
              expanded={guid === connectorGuid}
              onChange={(_, expanded) => {
                if (expanded) setConnector(guid, element_guid)
              }}
              data-cy={`tensile-support-form-${index}`}
            >
              <AccordionSummary
                key={guid}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id={support.guid}
              >
                <Typography data-cy="tensile-support-title" variant="body2">
                  Transmitter {index + 1}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {guid === connectorGuid && (
                  <TensileTransmitterForm support={support} index={index} />
                )}
              </AccordionDetails>
            </FormAccordion>
          )
        })}
      </Box>
      {!!supports.length && (
        <Box display="flex" justifyContent="end" marginY={2}>
          <LoadingButton
            sx={{ marginRight: 1 }}
            onClick={onRecalculate}
            variant="outlined"
            color="primary"
            loading={isRecalculating}
            data-cy="btn-recalculate-tensile-targets"
          >
            Ziele neu berechnen
          </LoadingButton>
          <SaveButton data-cy="btn-save" type="submit" loading={isLoading}>
            Speichern
          </SaveButton>
        </Box>
      )}
    </Form>
  )
}

export default TensileTransmitterList
