export interface TargetGuidAndRelativePosition {
  relativePosition: string
  guid: string
}

export const getSupportsOfElement = (
  elementGuid: string,
  graph: VerticalTransmissionGraph,
): TargetGuidAndRelativePosition[] => {
  const supports = graph.element_supports.filter(support => support.element_guid === elementGuid)

  const targets = supports.map(support => {
    const supportTarget = graph.support_targets.find(
      supportTarget => supportTarget.support_guid === support.guid,
    ) // should be 1-1

    const target = graph.element_targets.find(target => supportTarget?.target_guid === target.guid)

    return {
      relativePosition: support.relative_position,
      guid: target?.element_guid,
    } as TargetGuidAndRelativePosition
  })

  return targets.filter(target => target.guid !== undefined)
}
