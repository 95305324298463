import React, { ReactElement } from 'react'
import { Grid, Stack, Typography } from '@mui/material'
import { InfoTooltip } from '@ui/feedback'
import { Checkbox, Label } from '@ui/forms'

interface Props {
  checkboxDisabled?: boolean
}

const ShearCheckSettingsFormFields = ({ checkboxDisabled = false }: Props): ReactElement | null => {
  return (
    <Stack direction="column">
      <Typography align="center" mb={2}>
        Querkraftabminderung
      </Typography>
      <Grid container spacing={2} xs={12}>
        <Grid item xs={6}>
          <Label>Auflagernahe Punktlasten abmindern </Label>
        </Grid>
        <Grid item xs={1}>
          <InfoTooltip
            text="der Querkraftanteil aus Punktlasten innerhalb des Abstands h zu Auflagern bleibt unberücksichtigt"
            secondLineText={
              'nicht erlaubt, wenn Ausklinkung am Auflager.' +
              (checkboxDisabled
                ? ' Änderungen können nur in der Lokalen Ansicht vorgenommen werden'
                : '')
            }
          />
        </Grid>
        <Grid item xs={5}>
          <Checkbox
            name="shear_check_settings.reduce_point_loads_close_to_supports"
            disabled={checkboxDisabled}
          />
        </Grid>
        <Grid item xs={6}>
          <Label>Bemessungsquerkraft im Abstand h vom Auflager</Label>
        </Grid>
        <Grid item xs={1}>
          <InfoTooltip
            text="die Bemessungsquerkraft wird im Abstand h zu Auflagern bestimmt anstatt in der rechnerischen Auflagerlinie"
            secondLineText={
              'nur erlaubt, wenn Querkräfte an Oberseite des Trägers angreifen (z. B. nicht bei anschließenden Schwalbenschwänzen).' +
              (checkboxDisabled
                ? ' Änderungen können nur in der Lokalen Ansicht vorgenommen werden'
                : '')
            }
          />
        </Grid>
        <Grid item xs={5}>
          <Checkbox name="shear_check_settings.reduce_shear_force" disabled={checkboxDisabled} />
        </Grid>
      </Grid>
    </Stack>
  )
}

export default ShearCheckSettingsFormFields
