import { useEffect, useMemo } from 'react'
import { useHotkeys, useHotkeysContext } from 'react-hotkeys-hook'
import { some } from 'lodash-es'
import { v4 } from 'uuid'
import { useTapelineStore } from '@modugen/scene/lib'
import { useControlStore } from '@editorStores'
import { STRUCTURAL_PLANNING_DRAWER_ESC_SCOPE_PREFIX } from './useStructuralPlanningDrawerEsc'

export const STRUCTURAL_PLANNING_FORM_ESC_SCOPE_PREFIX = 'structural-planning-form'

const useStructuralPlanningDrawerEsc = (callback: () => void, enabled: boolean) => {
  const scopeId = useMemo(() => STRUCTURAL_PLANNING_FORM_ESC_SCOPE_PREFIX + v4(), [])

  const { enableScope, disableScope, enabledScopes } = useHotkeysContext()

  const isTapelineDrawing = useTapelineStore(state => state.isDrawing)
  const actionMode = useControlStore(state => state.actionMode)

  const globalEnabled = useMemo(
    () => enabled && !isTapelineDrawing && actionMode !== 'hide',
    [enabled, isTapelineDrawing, actionMode],
  )

  const drawerScopeEnabled = useMemo(
    () =>
      some(enabledScopes, scope => scope.startsWith(STRUCTURAL_PLANNING_DRAWER_ESC_SCOPE_PREFIX)),
    [enabledScopes],
  )

  useEffect(() => {
    if (globalEnabled) enableScope(scopeId)
    else disableScope(scopeId)

    return () => disableScope(scopeId)
  }, [globalEnabled, enableScope, disableScope, scopeId])

  useHotkeys('esc', callback, { scopes: [scopeId], enabled: !drawerScopeEnabled }, [callback])
}

export default useStructuralPlanningDrawerEsc
