import { useMemo } from 'react'
import { memoize } from 'lodash-es'
import { useModelStore } from '../stores/modelStore'
import { positionToDomain } from '../utils/positionToDomain'

const memoizedAllDomains = memoize(
  (domains: Domain[], rips: PositionForVertical[], lintels: PositionForVertical[]) => {
    const positionDomains = [...rips, ...lintels].map(position => positionToDomain(position))
    return [...positionDomains, ...domains]
  },
)

const useAllDomains = (): Domain[] => {
  const domains = useModelStore(state => state.domains)
  const rips = useModelStore(state => state.model.rips)
  const lintels = useModelStore(state => state.model.lintels)

  const allDomains = useMemo<Domain[]>(
    () => memoizedAllDomains(domains, rips, lintels),
    [domains, lintels, rips],
  )

  return allDomains
}

export default useAllDomains
