import convert from 'convert'

export const nmToNmm2 = (val: number) => {
  return val / convert(1, 'm2').to('mm2')
}

export const mPerSecondToMmPerMinute = (val: number) => {
  return (val * convert(1, 'meter').to('millimeter')) / convert(1, 'second').to('minute')
}

export const fireExposedSidesToReadable = (val: FireExposedSides): string => {
  let fireExposedSidesList: string[] = []

  if (val.top) {
    fireExposedSidesList = [...fireExposedSidesList, 'oben']
  }
  if (val.bottom) {
    fireExposedSidesList = [...fireExposedSidesList, 'unten']
  }
  if (val.left) {
    fireExposedSidesList = [...fireExposedSidesList, 'links']
  }
  if (val.right) {
    fireExposedSidesList = [...fireExposedSidesList, 'rechts']
  }

  return fireExposedSidesList.join(', ')
}
