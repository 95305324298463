import { useHotkeys } from 'react-hotkeys-hook'
import { OptionsOrDependencyArray } from 'react-hotkeys-hook/dist/types'

// https://app.clickup.com/t/2590559/MDG-3064
// https://www.toptal.com/developers/keycode
const eventCodeToStoreyMap: { [key: string]: string } = {
  Digit0: '0',
  Digit1: '1',
  Digit2: '2',
  Digit3: '3',
  Digit4: '4',
  Digit5: '5',
  Digit6: '6',
  Digit7: '7',
  Digit8: '8',
  Digit9: '9',
  Numpad0: '0',
  Numpad1: '1',
  Numpad2: '2',
  Numpad3: '3',
  Numpad4: '4',
  Numpad5: '5',
  Numpad6: '6',
  Numpad7: '7',
  Numpad8: '8',
  Numpad9: '9',
  Insert: '0',
  End: '1',
  ArrowDown: '2',
  PageDown: '3',
  ArrowLeft: '4',
  Clear: '5',
  ArrowRight: '6',
  Home: '7',
  ArrowUp: '8',
  PageUp: '9',
}

export const useHotkeys_Ctrl_0to9 = (
  callback: (storeyId: string) => void,
  options: OptionsOrDependencyArray = {},
  dependencies: OptionsOrDependencyArray = [],
) => {
  return useHotkeys(
    [
      // these keys are used for digit keys and when numlock is on
      '0',
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      // adding ctrl modifiers
      'CTRL+0',
      'CTRL+1',
      'CTRL+2',
      'CTRL+3',
      'CTRL+4',
      'CTRL+5',
      'CTRL+6',
      'CTRL+7',
      'CTRL+8',
      'CTRL+9',
      // numpad keys when numlock is off
      'Insert',
      'End',
      'ArrowDown',
      'PageDown',
      'ArrowLeft',
      'Clear',
      'ArrowRight',
      'Home',
      'ArrowUp',
      'PageUp',
      // adding ctrl modifiers
      'CTRL+Insert',
      'CTRL+End',
      'CTRL+ArrowDown',
      'CTRL+PageDown',
      'CTRL+ArrowLeft',
      'CTRL+Clear',
      'CTRL+ArrowRight',
      'CTRL+Home',
      'CTRL+ArrowUp',
      'CTRL+PageUp',
    ],
    event => {
      if (event.ctrlKey) {
        const storeyId = eventCodeToStoreyMap[event.code]
        if (storeyId) {
          callback(storeyId)
        }
      }
    },
    { preventDefault: true, ...options },
    dependencies,
  )
}

export const useHotkeys_0to9WithNumpad = (
  callback: (storeyId: string) => void,
  options: OptionsOrDependencyArray = {},
  dependencies: OptionsOrDependencyArray = [],
) => {
  return useHotkeys(
    [
      // these keys are used for digit keys and when numlock is on
      '0',
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      // numpad keys when numlock is off
      'Insert',
      'End',
      'ArrowDown',
      'PageDown',
      'ArrowLeft',
      'Clear',
      'ArrowRight',
      'Home',
      'ArrowUp',
      'PageUp',
    ],
    event => {
      if (!event.ctrlKey) {
        const storeyId = eventCodeToStoreyMap[event.code]
        if (storeyId) {
          callback(storeyId)
        }
      }
    },
    { preventDefault: true, ...options },
    dependencies,
  )
}
