import React, { ReactElement } from 'react'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { Collapse, IconButton, TableCell, TableRow, Tooltip, Typography } from '@mui/material'
import { Box } from '@ui/structure'
import AdditionalReducedCrossSectionFireCheckInformation from './ReducedCrossSectionFireCheckInformation'

interface Props {
  typeReadable: string
  check: ReducedCrossSectionFireCheck
  index: number
  isOpen: boolean
  setOpenIndex: (index: number | null) => void
  openCheck: (checkPosition?: number) => void
}

const ReducedCrossSectionFireCheckRow = ({
  typeReadable,
  check,
  isOpen,
  index,
  setOpenIndex,
  openCheck,
}: Props): ReactElement => {
  const { accepted } = check

  const setOpen = () => {
    openCheck(undefined)
    setOpenIndex(isOpen ? null : index)
  }

  return (
    <>
      <TableRow
        hover
        onClick={setOpen}
        tabIndex={-1}
        key={index}
        sx={{ '& > .MuiTableCell-root': { borderBottom: 0, paddingY: 0 } }}
      >
        <TableCell sx={{ paddingLeft: 0 }}>
          <IconButton size="small" onClick={setOpen}>
            {isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>

        <TableCell sx={{ paddingLeft: 0 }}>
          <Tooltip title={typeReadable} placement="top">
            <Typography>{typeReadable}</Typography>
          </Tooltip>
        </TableCell>

        <TableCell>{accepted ? 'OK' : 'ERR'}</TableCell>

        <TableCell>-</TableCell>
      </TableRow>

      <TableRow sx={{ '& > .MuiTableCell-root': { mb: 20 } }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
          <Collapse in={isOpen} timeout="auto" unmountOnExit>
            <Box mt={2}>
              <AdditionalReducedCrossSectionFireCheckInformation check={check} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

export default ReducedCrossSectionFireCheckRow
