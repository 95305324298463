import React, { ReactElement, useMemo } from 'react'
import { ColorRepresentation } from 'three'
import { toImmutable } from '@modugen/scene/lib/utils'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'
import { Line } from '@react-three/drei'

interface Props {
  direction: ImmutableVector3
  origin: ImmutableVector3
  normal: ImmutableVector3
  length: number
  color?: string | ColorRepresentation
}

const ArrowOrientation = ({
  direction,
  origin,
  length,
  normal,
  color = '#000',
}: Props): ReactElement => {
  const geometry = useMemo(() => {
    const directionNormal = direction.normalize()
    const end = origin.add(directionNormal.multiplyScalar(length))

    const helperLength = length / 4
    const helperAngle = 22

    const startHelperDirection = directionNormal.v
      .applyAxisAngle(normal.v, helperAngle * (Math.PI / 180))
      .normalize()
    const startHelperEnd = origin.add(
      toImmutable(startHelperDirection.clone().multiplyScalar(helperLength)),
    )

    const endHelperDirection = directionNormal.v
      .applyAxisAngle(normal.v, (-(180 - helperAngle) * Math.PI) / 180)
      .normalize()
    const endHelperEnd = end.add(
      toImmutable(endHelperDirection.clone().multiplyScalar(helperLength)),
    )

    return {
      line: [origin, end],
      start: [origin, startHelperEnd],
      end: [end, endHelperEnd],
    }
  }, [origin])

  return (
    <>
      <Line
        points={geometry.line.map(p => p.v)}
        color={color as ColorRepresentation}
        polygonOffset
        polygonOffsetFactor={0}
      />
      <Line
        points={geometry.start.map(p => p.v)}
        color={color as ColorRepresentation}
        polygonOffset
        polygonOffsetFactor={0}
      />
      <Line
        points={geometry.end.map(p => p.v)}
        color={color as ColorRepresentation}
        polygonOffset
        polygonOffsetFactor={0}
      />
    </>
  )
}

export default ArrowOrientation
